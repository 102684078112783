<template>
    <div style="padding: 30px 50px 100px 50px">
        <v-alert
            v-model="alertChangeStatus"
            type="success"
            dismissible>
          ¡Los datos de la cita han sido actualizados!.
        </v-alert>
        <v-alert
            v-model="alertUserAsigned"
            type="success"
            dismissible>
          ¡Usuario asignado!.
        </v-alert>
        <v-alert
            v-model="alertOxxoTerminated"
            type="success"
            dismissible>
          ¡La devolucion a sido realizada!.
        </v-alert>
        <v-alert
            v-model="alertOxxoEmail"
            type="success"
            dismissible>
          ¡El correo ha sido enviado al paciente!.
        </v-alert>
        <v-alert
            v-model="alertReportGenerated"
            type="success"
            dismissible>
          ¡El reporte ha sido generado!.
        </v-alert>
        <v-alert
            v-model="alertError"
            type="error"
            dismissible>
          ¡Error al enviar o actualizar la infomracion de la cita!.
        </v-alert>
        <v-alert
            v-model="alertErrorReactivate"
            type="error"
            dismissible>
          ¡Error al intentar reactivar la cita!.
        </v-alert>
        <v-alert
            v-model="alertErrorChargeInfo"
            type="error"
            dismissible>
          ¡Error no se pudo cargar la informacion, favor de reintentar!.
        </v-alert>
        <div v-if="level == 0">
            <v-container>
                <v-row>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-card @click="fillTable(0)" hover>
                                    <v-list-item>
                                        <v-card-title>
                                            Nuevas
                                        </v-card-title>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card @click="fillTable(1)" hover>
                                    <v-list-item>
                                        <v-card-title>
                                            En proceso
                                        </v-card-title>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card @click="fillTable(2)" hover>
                                    <v-list-item>
                                        <v-card-title>
                                            Enviadas
                                        </v-card-title>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card @click="fillTable(3)" hover>
                                    <v-list-item>
                                        <v-card-title>
                                            Pendientes
                                        </v-card-title>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card @click="fillTable(4)" hover>
                                    <v-list-item>
                                        <v-card-title>
                                            Terminadas
                                        </v-card-title>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card @click="fillTable(5)" hover>
                                    <v-list-item>
                                        <v-card-title>
                                            Rechazadas
                                        </v-card-title>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container fluid>
                        <div v-if="type == 0">
                            <v-card>
                                <v-card-title>
                                    Nuevas
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="buscar"
                                        append-icon="mdi-magnify"
                                        label="Buscar"
                                        single-line
                                        hide-details>
                                    </v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="oxxoNew"
                                    :search="buscar">

                                    <template v-slot:top>
                                        <v-dialog
                                            v-model="dialogIni"
                                            max-width="500px">

                                            <v-card>
                                                <v-card-title>
                                                    <h3>Cambiar Etapa</h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <h4>La informacion proporcionada es correcta?</h4>
                                                        <v-checkbox
                                                            v-model="infoNoOK"
                                                            hide-details
                                                            class="shrink"
                                                            label="No">
                                                        </v-checkbox>
                                                        <v-text-field
                                                            v-model="comments"
                                                            :disabled="!infoNoOK"
                                                            label="Indica porque es incorrecta">
                                                        </v-text-field>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="red"
                                                        text
                                                        @click="close">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn
                                                        color="blue darken-1"
                                                        class="my-button"
                                                        :loading="!loading"
                                                        @click="cambiarEtapaInicial">
                                                        Siguiente Etapa
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <v-dialog
                                            v-model="dialogUserAsigned"
                                            max-width="500px">

                                            <v-card>
                                                <v-card-title>
                                                    <h3>Asignar usuario</h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <h4>Escribe la direccion de correo?</h4>
                                                        <v-autocomplete
                                                            v-model="assignedU"
                                                            :items="items"
                                                            label="Correo">
                                                        </v-autocomplete>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="red"
                                                        text
                                                        @click="close">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn
                                                        color="blue darken-1"
                                                        class="my-button"
                                                        :loading="!loading"
                                                        @click="asignarUsuario">
                                                        Asignar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </template>

                                    <template v-slot:item.devolucion="{ item }">
                                    <v-chip
                                        :color="getColor(item.devolucion)"
                                        dark
                                    >
                                        <div v-if="item.devolucion == 1">
                                            OK
                                        </div>
                                        <div v-else>N/OK</div>
                                    </v-chip>
                                    </template>

                                    <template v-slot:item.asignado="{ item }">
                                        <div v-if="item.asignado != null">
                                            {{ item.asignado.replace("@salud-digna.org", "") }}
                                        </div>
                                        <div v-else>
                                            <v-btn
                                            @click="ME1(item.idCitaSisPrev)"
                                            :loading="!loadingAssign"
                                            class="my-button">
                                            Asignar
                                            </v-btn>
                                        </div>
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        {{ (item.createdAt).substr(0, 19).replace('T', ' ') }}
                                    </template>

                                    <template v-slot:item.esvalida="{ item }">
                                        <v-btn
                                        @click="isNotValid(item.idCitaSisPrev, item.email)"
                                        :loading="!loadingisValid"
                                        class="my-button">
                                        No es valida
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.ver="{ item }">
                                        <v-btn
                                        @click="verMas(item.idCitaSisPrev)"
                                        :loading="!loadingVer"
                                        class="my-button">
                                        Ver
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.etapa="{ item }">
                                        <v-btn
                                        @click="ME0(item.idCitaSisPrev, 0)"
                                        class="my-button">
                                        Siguiente Etapa
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </div>
                        <div v-if="type == 1">
                            <div class="d-flex justify-end">
                              <v-btn
                                class="my-button"
                                :loading="!loading"
                                @click="cambiarEtapaEnv"
                              >
                              Siguiente Etapa
                              </v-btn>
                              <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                              <v-btn
                                dark
                                color="#FFAB0B"
                                :loading="!loadingReport"
                                @click="descargarexcel"
                              >
                                <v-img
                                    alt="arriba"
                                    contain
                                    src="../assets/imgs/iconos/download.png"
                                    style="padding-right: 15px"
                                />
                                Descargar reporte
                              </v-btn>
                            </div>
                            <v-card>
                                <v-card-title>
                                    Proceso
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="buscar"
                                        append-icon="mdi-magnify"
                                        label="Buscar"
                                        single-line
                                        hide-details>
                                    </v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers_proc"
                                    :items="oxxoProc"
                                    :search="buscar">

                                    <template v-slot:item.devolucion="{ item }">
                                    <v-chip
                                        :color="getColor(item.devolucion)"
                                        dark
                                    >
                                        <div v-if="item.devolucion == 1">
                                            OK
                                        </div>
                                        <div v-else>N/OK</div>
                                    </v-chip>
                                    </template>

                                    <template v-slot:item.asignado="{ item }">
                                        <div v-if="item.asignado != null">
                                            {{ item.asignado.replace("@salud-digna.org", "") }}
                                        </div>
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        {{ (item.createdAt).substr(0, 19).replace('T', ' ') }}
                                    </template>

                                    <template v-slot:item.ver="{ item }">
                                        <v-btn
                                        @click="verMas(item.idCitaSisPrev)"
                                        :loading="!loadingVer"
                                        class="my-button">
                                        Ver
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </div>
                        <div v-if="type == 2">
                            <v-card>
                                <v-card-title>
                                    Enviadas
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="buscar"
                                        append-icon="mdi-magnify"
                                        label="Buscar"
                                        single-line
                                        hide-details>
                                    </v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers_sent"
                                    :items="oxxoEnv"
                                    :search="buscar">

                                    <template v-slot:top>
                                        <v-dialog
                                            v-model="dialogEnd"
                                            max-width="500px">

                                            <v-card>
                                                <v-card-title>
                                                    <h3>Terminar devolucion</h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <h4>Cuentas con el voucher de la devolucion?</h4>
                                                        <v-checkbox
                                                            v-model="voucherAvaible"
                                                            hide-details
                                                            class="shrink"
                                                            label="Si">
                                                        </v-checkbox>
                                                        <v-file-input
                                                        accept="application/pdf"
                                                        label="Agrega Voucher Pagado"
                                                        outlined dense
                                                        show-size
                                                        :disabled="!voucherAvaible"
                                                        v-model="voucher"
                                                        ></v-file-input>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="red"
                                                        text
                                                        @click="close">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn
                                                        :disabled="!voucherAvaible"
                                                        class="my-button"
                                                        :loading="!loadingEndCita"
                                                        @click="terminarOxxo">
                                                        Terminar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </template>

                                    <template v-slot:item.devolucion="{ item }">
                                    <v-chip
                                        :color="getColor(item.devolucion)"
                                        dark
                                    >
                                        <div v-if="item.devolucion == 1">
                                            OK
                                        </div>
                                        <div v-else>N/OK</div>
                                    </v-chip>
                                    </template>

                                    <template v-slot:item.asignado="{ item }">
                                        <div v-if="item.asignado != null">
                                            {{ item.asignado.replace("@salud-digna.org", "") }}
                                        </div>
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        {{ (item.createdAt).substr(0, 19).replace('T', ' ') }}
                                    </template>

                                    <template v-slot:item.ver="{ item }">
                                        <v-btn
                                        @click="verMas(item.idCitaSisPrev)"
                                        :loading="!loadingVer"
                                        class="my-button">
                                        Ver
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.etapa="{ item }">
                                        <v-btn
                                        @click="ME2(item.idCitaSisPrev, item.email)"
                                        class="my-button">
                                        Terminar
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </div>
                        <div v-if="type == 3">
                            <v-card>
                                <v-card-title>
                                    Pendientes
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="buscar"
                                        append-icon="mdi-magnify"
                                        label="Buscar"
                                        single-line
                                        hide-details>
                                    </v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers_pend"
                                    :items="oxxoPen"
                                    :search="buscar"
                                    :single-expand="true"
                                    :expanded.sync="expanded"
                                    item-key="idCitaSisPrev"
                                    show-expand>

                                    <template v-slot:top>
                                        <v-dialog
                                            v-model="dialogUpdateDataUser"
                                            max-width="500px">
                                    
                                            <v-card>
                                                <v-card-title>
                                                    <h3>Actualiza la informacion del usuario:</h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <h4>Llena los campos indicados</h4>
                                                        <v-text-field
                                                            v-model="newDataAsignTD"
                                                            label="Número de Tarjeta:">
                                                        </v-text-field>
                                                        <v-text-field
                                                            v-model="newDataAsignCb"
                                                            label="Clabe Interbancaria:">
                                                        </v-text-field>
                                                        <v-text-field
                                                            v-model="newDataAsignNB"
                                                            label="Nombre del banco:">
                                                        </v-text-field>
                                                        <v-text-field
                                                            v-model="newDataAsignNTB"
                                                            label="Nombre del titular de la tarjeta:">
                                                        </v-text-field>
                                                        <v-file-input
                                                        accept="image/*"
                                                        label="Añadir identificacion"
                                                        outlined dense
                                                        v-model="identificacion"
                                                        prepend-icon="mdi-camera"
                                                        ></v-file-input>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="red"
                                                        text
                                                        @click="close">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn
                                                        class="my-button"
                                                        :loading="!loading"
                                                        @click="actualizaInformacion(item.idCitaSisPrev)">
                                                        Actualizar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </template>

                                    <template v-slot:item.devolucion="{ item }">
                                    <v-chip
                                        :color="getColor(item.devolucion)"
                                        dark
                                    >
                                        <div v-if="item.devolucion == 1">
                                            OK
                                        </div>
                                        <div v-else>N/OK</div>
                                    </v-chip>
                                    </template>

                                    <template v-slot:item.asignado="{ item }">
                                        <div v-if="item.asignado != null">
                                            {{ item.asignado.replace("@salud-digna.org", "") }}
                                        </div>
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        {{ (item.createdAt).substr(0, 19).replace('T', ' ') }}
                                    </template>

                                    <template v-slot:item.ver="{ item }">
                                        <v-btn
                                        @click="verMas(item.idCitaSisPrev)"
                                        :loading="!loadingVer"
                                        class="my-button">
                                        Ver
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.actualiza="{ item }">
                                        <v-btn
                                        @click="ME3(item.idCitaSisPrev)"
                                        class="my-button">
                                        Actualiza
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.correo="{ item }">
                                        <v-btn
                                        @click="enviaCorreoInfoError(item.idCitaSisPrev, item.email)"
                                        :loading="!loadingSentEmail"
                                        class="my-button">
                                        Enviar<br/>Correo
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.etapa="{ item }">
                                        <v-btn
                                        @click="cambiarEtapa(item.idCitaSisPrev, 0, '')"
                                        :loading="!loadingChangeStep"
                                        class="my-button">
                                        Siguiente Etapa
                                        </v-btn>
                                    </template>

                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length">
                                          {{ item.etapa_motivo }}
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </div>
                        <div v-if="type == 4">
                            <v-card>
                                <v-card-title>
                                    Terminadas
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="buscar"
                                        append-icon="mdi-magnify"
                                        label="Buscar"
                                        single-line
                                        hide-details>
                                    </v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers_end"
                                    :items="oxxoTer"
                                    :search="buscar">

                                    <template v-slot:item.devolucion="{ item }">
                                    <v-chip
                                        :color="getColor(item.devolucion)"
                                        dark
                                    >
                                        <div v-if="item.devolucion == 1">
                                            OK
                                        </div>
                                        <div v-else>N/OK</div>
                                    </v-chip>
                                    </template>

                                    <template v-slot:item.asignado="{ item }">
                                        <div v-if="item.asignado != null">
                                            {{ item.asignado.replace("@salud-digna.org", "") }}
                                        </div>
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        {{ (item.createdAt).substr(0, 19).replace('T', ' ') }}
                                    </template>

                                    <template v-slot:item.ver="{ item }">
                                        <v-btn
                                        @click="verMas(item.idCitaSisPrev)"
                                        :loading="!loadingVer"
                                        class="my-button">
                                        Ver
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </div>
                        <div v-if="type == 5">
                            <v-card>
                                <v-card-title>
                                    Rechazadas
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="buscar"
                                        append-icon="mdi-magnify"
                                        label="Buscar"
                                        single-line
                                        hide-details>
                                    </v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers_invalid"
                                    :items="oxxoFailed"
                                    :search="buscar">

                                    <template v-slot:item.devolucion="{ item }">
                                    <v-chip
                                        :color="getColor(item.devolucion)"
                                        dark
                                    >
                                        <div v-if="item.devolucion == 1">
                                            OK
                                        </div>
                                        <div v-else>N/OK</div>
                                    </v-chip>
                                    </template>

                                    <template v-slot:item.asignado="{ item }">
                                        <div v-if="item.asignado != null">
                                            {{ item.asignado.replace("@salud-digna.org", "") }}
                                        </div>
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        {{ (item.createdAt).substr(0, 19).replace('T', ' ') }}
                                    </template>

                                    <template v-slot:item.ver="{ item }">
                                        <v-btn
                                        @click="verMas(item.idCitaSisPrev)"
                                        :loading="!loadingVer"
                                        class="my-button">
                                        Ver
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.reactivate="{ item }">
                                        <v-btn
                                        @click="reactivateCita(item.idCitaSisPrev)"
                                        :loading="!loadingisValid"
                                        class="my-button">
                                        Reactivar
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </div>
                    </v-container>
                </v-row>
            </v-container>
        </div>
        <div v-else-if="level == 1">
            <v-row>
                <v-container>
                <v-row>
                    <v-col md="6">
                    <v-card hover class="myCardClass" style="padding: 0px">
                        <v-list-item style="padding: 0px">
                        <v-list-item-content style="padding: 0px">
                            <v-card-title style="padding: 0px">
                            <div class="card-header">
                                <p class="header-text">Información del paciente</p>
                            </div>
                            </v-card-title>
                            <v-card-text style="padding-top: 15px">
                            <p><b>Nombre:</b> {{ info.nombre }} {{ info.paterno }}
                            {{ info.materno }}</p>
                            <p><b>FechaNacimiento:</b> {{ info.fechaNacimiento }}</p>
                            <p><b>Correo:</b> {{ info.correo }}</p>
                            <p style="margin-bottom: 4px !important"><b>Celular:</b> {{ info.telefonoCelular }}</p>
                            </v-card-text>
                        </v-list-item-content>
                        </v-list-item>
                    </v-card>
                    </v-col>
                    <v-col md="6">
                    <v-card hover class="myCardClass" style="padding: 0px">
                        <v-list-item style="padding: 0px">
                        <v-list-item-content style="padding: 0px">
                            <v-card-title style="padding: 0px">
                            <div class="card-header">
                                <p class="header-text">Información de la cita</p>
                            </div>
                            </v-card-title>
                            <v-card-text style="padding-top: 15px">
                            <p><b class="mb-2">Sucursal:</b> {{ info.sucursal }}</p>
                            <p><b class="mb-2">Folio cita:</b> {{ info.idCitaSisPrev }}</p>
                            <p><b class="mb-2">Estudio:</b> {{ info.subEstudioVal }}</p>
                            <p><b class="mb-2">Fecha:</b> {{ info.citaFecha }}</p>
                            <p><b class="mb-2">Hora:</b> {{ info.citaHora }}</p>
                            <p style="margin-bottom: 4px !important"><b>Monto:</b> {{ info.montoTotal }}</p>
                            </v-card-text>
                        </v-list-item-content>
                        </v-list-item>
                    </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-container>
                    <v-card hover class="myCardClass" style="padding: 0px">
                        <v-card-title style="padding: 0px">
                        <div class="card-header">
                            <p class="header-text">Información de la devolución</p>
                        </div>
                        </v-card-title>
                        <v-card-text>
                        <v-row>
                            <v-col md="6">
                            <v-list-item>
                                <v-list-item-content>
                                <v-list-item-title
                                    ><p><b class="mb-2">Folio devolucion:</b>
                                    {{ info.folioCancelacion }}</p></v-list-item-title
                                >
                                <div>
                                    <div v-if="info.createdAt">
                                    <p><v-list-item-title
                                        ><b class="mb-2">Fecha:</b>
                                        {{ (info.createdAt).substr(0, 19).replace('T', ' ') }}</v-list-item-title
                                    ></p>
                                    </div>
                                    <div v-else>
                                    <p><v-list-item-title
                                        ><b class="mb-2">Fecha:</b>
                                        {{ info.fecha }}</v-list-item-title
                                    ></p>
                                    </div>
                                </div>
                                <v-list-item-title
                                    ><p><b class="mb-2">Motivo:</b>
                                    {{ info.motivoCancelacion }}</p></v-list-item-title
                                >
                                <div>
                                    <div v-if="info.idDevolucion">
                                    <p><v-list-item-title
                                        ><b class="mb-2">Estado:</b> Devolucion realizada con
                                        exito</v-list-item-title
                                    ></p>
                                    </div>
                                    <div v-else>
                                    <div v-if="info.status == 1">
                                        <p><v-list-item-title
                                        ><b class="mb-2">Estado:</b> Pendiente</v-list-item-title
                                        ></p>
                                    </div>
                                    <div v-else>
                                        <div v-if="info.status == 'true'">
                                        <div
                                            v-if="
                                            info.mensaje ==
                                            'La cita ya cuenta con una devolución'
                                            "
                                        >
                                            <p><v-list-item-title
                                            ><b class="mb-2">Estado:</b> Ya tiene una
                                            devolucion</v-list-item-title
                                            ></p>
                                        </div>
                                        <div v-else>
                                            <p><v-list-item-title
                                            ><b class="mb-2">Estado:</b>
                                            {{ info.mensaje }}</v-list-item-title
                                            ></p>
                                        </div>
                                        </div>
                                        <div v-else>
                                        <div v-if="info.mensaje == 'La cita ya cuenta con una devolución'">
                                            <p><v-list-item-title
                                            ><b class="mb-2">Estado:</b> Ya tiene una
                                            devolucion</v-list-item-title
                                            ></p>
                                        </div>
                                        <div v-else>
                                            <div v-if="info.devolucion">
                                            <p><v-list-item-title
                                                ><b class="mb-2">Estado:</b> Devolucion realizada con
                                                exito</v-list-item-title
                                            ></p>
                                            </div>
                                            <div v-else>
                                            <p><v-list-item-title
                                                ><b class="mb-2">Estado:</b> Devolucion no
                                                realizada</v-list-item-title
                                            ></p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="info.plataforma == 1">
                                    <p><v-list-item-title
                                        ><b class="mb-2">Plataforma:</b> Web</v-list-item-title
                                    ></p>
                                    </div>
                                    <div v-else>
                                    <p><v-list-item-title
                                        ><b class="mb-2">Plataforma:</b> WhatsApp</v-list-item-title
                                    ></p>
                                    </div>
                                </div>
                                </v-list-item-content>
                            </v-list-item>
                            </v-col>
                            <v-col md="6">
                            <v-list-item>
                                <v-list-item-content>
                                <div>
                                    <div v-if="info.idTipoPago != 4">
                                    <div v-if="info.mismoDia == 0">
                                        <p><v-list-item-title
                                        ><b class="mb-2">Se realizo el dia de la cita:</b>
                                        No</v-list-item-title
                                        ></p>
                                    </div>
                                    <div v-else>
                                        <p><v-list-item-title
                                        ><b class="mb-2">Se realizo el dia de la cita:</b>
                                        Si</v-list-item-title
                                        ></p>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="info.idTipoPago == 3">
                                    <div v-if="info.idPaquete == 1">
                                        <p><v-list-item-title
                                        ><b class="mb-2">Medio de pago:</b>
                                        Conekta</v-list-item-title
                                        ></p>
                                    </div>
                                    <div v-else>
                                        <p><v-list-item-title
                                        ><b class="mb-2">Medio de pago:</b>
                                        Billpocket</v-list-item-title
                                        ></p>
                                    </div>
                                    </div>
                                    <div v-else>
                                    <div v-if="info.idTipoPago == 4">
                                        <p><v-list-item-title
                                        ><b class="mb-2">Medio de pago:</b> OXXO
                                        PAY</v-list-item-title
                                        ></p>
                                    </div>
                                    <div v-else>
                                        <div v-if="info.idTipoPago == 5">
                                        <p><v-list-item-title
                                            ><b class="mb-2">Medio de pago:</b> COPPEL
                                            PAY</v-list-item-title
                                        ></p>
                                        </div>
                                        <div v-else>
                                        <p><v-list-item-title
                                            ><b class="mb-2">Medio de pago:</b>
                                            N/A</v-list-item-title
                                        ></p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="info.idDevolucion">
                                    <p><v-list-item-title
                                        ><b class="mb-2">idDevolucion:</b>
                                        {{ info.idDevolucion }}</v-list-item-title
                                    ></p>
                                    </div>
                                    <div v-else>
                                    <div v-if="info.idTipoPago == 4">
                                        <div v-if="info.devolucion">
                                        <p><v-list-item-title
                                            ><b class="mb-2">Devolucion realizada:</b> SI</v-list-item-title
                                        ></p>
                                        </div>
                                        <div v-else>
                                        <p><v-list-item-title
                                            ><b class="mb-2">Devolucion realizada:</b> NO <v-btn @click="oxxo" :loading="status" class="my-button">Confirmar devolucion</v-btn></v-list-item-title
                                        ></p>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p><v-list-item-title
                                        ><b class="mb-2">idDevolucion:</b> No
                                        disponible</v-list-item-title
                                        ></p>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="info.idOrdenPago">
                                    <p>
                                        <v-list-item-title>
                                        <b class="mb-2">idOrdenPago:</b> {{info.idOrdenPago}}
                                        </v-list-item-title>
                                    </p>
                                    </div>
                                    <div v-else>
                                    <p>
                                        <v-list-item-title>
                                        <b class="mb-2">idOrdenPago:</b> No cuenta con IdOrdenPago
                                        </v-list-item-title>
                                    </p>
                                    </div>
                                </div>
                                <div>
                                    <div v-if="info.mismoDia == 1">
                                    <div v-if="info.status == 1">
                                        <p><v-list-item-title
                                        ><b class="mb-2">Devolucion realizada en:</b> No se realiza
                                        aun</v-list-item-title
                                        ></p>
                                    </div>
                                    <div v-else>
                                        <div v-if="info.status == true">
                                        <p><v-list-item-title
                                            ><b class="mb-2">Devolucion realizada en:</b> Cron</v-list-item-title></p>
                                        </div>
                                        <div v-else>
                                        <p><v-list-item-title
                                            ><b class="mb-2">Devolucion realizada en:</b> Cron</v-list-item-title
                                        ></p>
                                        </div>
                                    </div>
                                    </div>
                                    <div v-else>
                                    <p><v-list-item-title
                                        ><b class="mb-2">Devolucion realizada en:</b>
                                        LP</v-list-item-title
                                    ></p>
                                    </div>
                                </div>
                                </v-list-item-content>
                            </v-list-item>
                            </v-col>
                        </v-row>
                        </v-card-text>
                    </v-card>
                    </v-container>
                </v-row>
                <div v-if="info.idTipoPago == 4">
                    <v-row>
                    <v-container>
                        <v-card hover class="myCardClass" style="padding: 0px">
                        <v-list-item style="padding: 0px">
                            <v-list-item-content style="padding: 0px">
                            <v-card-title style="padding: 0px">
                                <div class="card-header">
                                <p class="header-text">Información formulario</p>
                                </div>
                            </v-card-title>
                            <v-card-text style="padding-top: 15px">
                                <p><b class="mb-2">Banco:</b> {{ info.banco }}</p>
                                <p><b class="mb-2">Numero tarjeta:</b> {{ info.numero_tarjeta }}</p>
                                <p><b class="mb-2">Clabe interbancaria:</b> {{ info.clabe_interbancaria }}</p>
                                <p><b class="mb-2">Correo Electronico:</b> {{ info.correo_form }}</p>
                                <v-img alt="LP" class="mr-2" contain min-width="32" v-bind:src="`${info.ife_url}`" width="480"/>
                            </v-card-text>
                            </v-list-item-content>
                        </v-list-item>
                        </v-card>
                    </v-container>
                    </v-row>
                </div>
                </v-container>
                <v-btn @click="regresar" class="my-button" style="margin-top: 40px"
                    >Regresar</v-btn
                    >
            </v-row>
        </div>
    </div>
</template>

<style>
.my-input {
  border: 0.5px solid #6c90c0 !important;
  padding: 8px 16px !important;
  width: 100% !important;
}

.my-button {
  background-color: #6c90c0 !important;
  color: white !important;
  text-transform: unset !important;
  padding: 5px 10px !important;
}

.devoluciones-header{
  color: #6c90c0;
}

.fecha-header {
  padding-top: 15px;
  font-size: 15px;
}

.mb-2{
  margin-bottom: 5px;
}
</style>

<script>
    import devolucionesService from "../services/devoluciones"
    import oxxoService from "../services/oxxo"

    export default {
        data: () => ({
            folio: null,
            email: null,
            comments: null,
            assignedU: null,
            dialogIni: false,
            dialogEnd: false,
            dialogUserAsigned: false,
            dialogUpdateDataUser: false,
            status: false,
            errorinfomacion: null,
            errorperiodo: null,
            alertChangeStatus: false,
            alertUserAsigned: false,
            alertOxxoTerminated: false,
            alertOxxoEmail: false,
            alertError: false,
            alertErrorReactivate: false,
            alertErrorChargeInfo: false,
            alertReportGenerated: false,
            level: 0,
            buscar: '',
            info: null,
            type: 0,
            type0: null,
            step: null,
            expanded: [],
            singleExpand: false,
            infoNoOK: false,
            voucher:null,
            voucherAvaible: false,
            newDataAsignTD: '',
            newDataAsignCb: '',
            newDataAsignNB: '',
            newDataAsignNTB: '',
            sendingED: true,
            sendingDU: true,
            loading: true,
            loadingReport: true,
            loadingVer: true,
            loadingAssign: true,
            loadingisValid: true,
            loadingChangeStep: true,
            loadingEndCita: true,
            loadingSentEmail: true,
            identificacion:null,
            items: [],
            headers: [
                { text: 'Folio Cita', align: 'start', sortable: false, value: 'idCitaSisPrev' },
                { text: 'Orden Pago', sortable: false, value: 'idOrdenPago' },
                { text: 'Folio Devolucion', value: 'folioCancelacion' },
                { text: 'Estado', sortable: false, value: 'devolucion' },
                { text: 'Atiende', value: 'asignado' },
                { text: 'Creada', value: 'createdAt' },
                { text: 'Valida', sortable: false, value: 'esvalida' },
                { text: '', sortable: false, value: 'ver' },
                { text: 'Etapa', sortable: false, value: 'etapa' }
            ],
            headers_sent: [
                { text: 'Folio Cita', align: 'start', sortable: false, value: 'idCitaSisPrev' },
                { text: 'Orden Pago', sortable: false, value: 'idOrdenPago' },
                { text: 'Folio Devolucion', value: 'folioCancelacion' },
                { text: 'Estado', sortable: false, value: 'devolucion' },
                { text: 'Atiende', value: 'asignado' },
                { text: 'Creada', value: 'createdAt' },
                { text: '', sortable: false, value: 'ver' },
                { text: 'Etapa', sortable: false, value: 'etapa' }
            ],
            headers_pend: [
                { text: 'Folio Cita', align: 'start', sortable: false, value: 'idCitaSisPrev' },
                { text: 'Orden Pago', sortable: false, value: 'idOrdenPago' },
                { text: 'Folio Devolucion', value: 'folioCancelacion' },
                { text: 'Estado', sortable: false, value: 'devolucion' },
                { text: 'Atiende', value: 'asignado' },
                { text: 'Creada', value: 'createdAt' },
                { text: 'Actualiza Informacion', sortable: false, value: 'actualiza' },
                { text: 'Informacion Invalida', sortable: false, value: 'correo' },
                { text: '', sortable: false, value: 'ver' },
                { text: 'Etapa', sortable: false, value: 'etapa' },
                { text: 'Motivo', value: 'data-table-expand' }
            ],
            headers_proc: [
                { text: 'Folio Cita', align: 'start', sortable: false, value: 'idCitaSisPrev' },
                { text: 'Orden Pago', sortable: false, value: 'idOrdenPago' },
                { text: 'Folio Devolucion', value: 'folioCancelacion' },
                { text: 'Estado', sortable: false, value: 'devolucion' },
                { text: 'Atiende', value: 'asignado' },
                { text: 'Creada', value: 'createdAt' },
                { text: '', sortable: false, value: 'ver' }
            ],
            headers_end: [
                { text: 'Folio Cita', align: 'start', sortable: false, value: 'idCitaSisPrev' },
                { text: 'Orden Pago', sortable: false, value: 'idOrdenPago' },
                { text: 'Folio Devolucion', value: 'folioCancelacion' },
                { text: 'Estado', sortable: false, value: 'devolucion' },
                { text: 'Atiende', value: 'asignado' },
                { text: 'Creada', value: 'createdAt' },
                { text: '', sortable: false, value: 'ver' }
            ],
            headers_invalid: [
                { text: 'Folio Cita', align: 'start', sortable: false, value: 'idCitaSisPrev' },
                { text: 'Orden Pago', sortable: false, value: 'idOrdenPago' },
                { text: 'Folio Devolucion', value: 'folioCancelacion' },
                { text: 'Estado', sortable: false, value: 'devolucion' },
                { text: 'Atiende', value: 'asignado' },
                { text: 'Creada', value: 'createdAt' },
                { text: '', sortable: false, value: 'ver' },
                { text: 'Reactivar', value: 'reactivate' }
            ],
            oxxoNew: [],
            oxxoProc: [],
            oxxoEnv: [],
            oxxoPen: [],
            oxxoTer: [],
            oxxoFailed: []
        }),
        mounted() {
            this.fillTable(0)
        },
        watch: {
          dialog (val) {
            val || this.close()
          }
        },
        methods: {
            fillTable(type) {
                if (type == 0) {
                    this.type0 = null
                    this.type = type
                } else {
                    this.type0 = type - 1
                    this.type = type
                }

                oxxoService.getOxxo(this.type0).then((res) => {
                    if (res.status) {
                        if (type == 0) {
                            this.oxxoNew = res.data
                            this.getUsers()
                        } else if (type == 1) {
                            this.oxxoProc = res.data
                        } else if (type == 2) {
                            this.oxxoEnv = res.data
                        } else if (type == 3) {
                            this.oxxoPen = res.data
                        } else if (type == 4) {
                            this.oxxoTer = res.data
                        } else if (type == 5) {
                            this.oxxoFailed = res.data
                        }
                    } else {
                        this.alertErrorChargeInfo = true
                        this.close()
                    }
                })
            },
            getUsers () {
                oxxoService.getUsers().then((res) => {
                    if (res.status) {
                        this.items = res.data
                    } else {
                        this.getUsers()
                    }
                })
            },
            getColor (devolucion) {
              if (devolucion == 1) return 'green'; else return 'red'
            },
            verMas (folioC) {
                this.loadingVer = false
                devolucionesService.getDevolucion(folioC).then((res) => {
                    if (res.status) {
                        this.info = res.data[0]
                        this.level = 1
                    } else {
                        this.alertErrorChargeInfo = true
                    }
              })
              this.close()
            },
            regresar () {
              this.level = 0
            },
            cambiarEtapaInicial () {
                if (!this.infoNoOK) {
                    this.comments = null
                }

                if (this.infoNoOK) {
                    this.step = 2
                }

                oxxoService.changeStep(this.folio, this.step, this.comments).then((res) => {
                    if (res.status) {
                        this.alertChangeStatus = true
                        this.close()
                        this.fillTable(0)
                    } else {
                        this.alertError = true
                        this.close()
                    }
                })
            },
            actualizaInformacion (folioC) {
                this.loading = false
                oxxoService.updateCitaInfo(this.newDataAsignTD, this.newDataAsignCb, this.newDataAsignNB, this.newDataAsignNTB, folioC).then((res) => {
                    if (res.status) {
                        this.alertChangeStatus = true
                        this.close()
                    } else {
                        this.alertError = true
                        this.close()
                    }
                })
            },
            cambiarEtapaEnv () {
                this.loadingChangeStep = false
                oxxoService.changeStepEnv().then((res) => {
                    if (res.status) {
                        this.close()
                        this.fillTable(2)
                    } else {
                        this.alertError = true
                        this.close()
                    }
                })
            },
            reactivateCita (folio) {
                this.loadingisValid = false
                oxxoService.reactivateCita(folio).then((res) => {
                    if (res.status) {
                        this.alertChangeStatus = true
                        this.close()
                        this.fillTable(5)
                    } else {
                        this.alertError = true
                        this.close()
                    }
                })
            },
            asignarUsuario () {
                this.loading = false
                oxxoService.asigneUser(this.folio, this.assignedU).then((res) => {
                    if (res.status) {
                        this.assignedU = null
                        this.alertChangeStatus = true
                        this.close()
                        this.fillTable(0)
                    } else {
                        this.alertError = true
                        this.close()
                    }
                })
            },
            isNotValid (folio, email) {
                this.loadingisValid = false
                oxxoService.sendEmailProcessFailed(folio, email).then((res) => {
                    if (res.status) {
                        this.alertChangeStatus = true
                        this.close()
                        this.fillTable(0)
                    } else {
                        this.alertError = true
                        this.close()
                    }
                })
            },
            terminarOxxo () {
                this.loadingEndCita = false
                if (this.voucherAvaible && this.voucher) {
                    oxxoService.sentDataEndCita(this.voucher, this.folio, this.email).then((res) => {
                        if (res.status) {
                            this.alertChangeStatus = true
                            this.alertOxxoTerminated = true
                            this.fillTable(2)
                            this.close()
                        } else {
                            this.alertError = true
                            this.fillTable(2)
                            this.close()
                        }
                    })
                }
            },
            descargarexcel() {
                this.loadingReport = false
                oxxoService.getReportDevValid().then((res) => {
                    if (res.status) {
                        oxxoService.getFile(res.url, res.name)
                        this.alertReportGenerated = true
                        this.close()
                    } else {
                        this.alertError = true
                        this.close()
                    }
                })
            },
            enviaCorreoInfoError (folioDE, emailDE) {
                this.loadingSentEmail = false
                oxxoService.sendEmailDataError(emailDE, folioDE).then((res) => {
                    if (res.status) {
                        this.alertOxxoEmail = true
                        this.close()
                    }else{
                        this.alertError = true
                        this.close()
                    }
                })
            },
            close () {
                this.folio = null,
                this.email = null,
                this.comments = null,
                this.assignedU = null,
                this.dialogIni = false,
                this.dialogEnd = false,
                this.dialogUserAsigned = false,
                this.dialogUpdateDataUser = false,
                this.status = false,
                this.errorinfomacion = null,
                this.errorperiodo = null,
                this.level = 0,
                this.buscar = '',
                this.info = null,
                this.step = null,
                this.expanded = [],
                this.singleExpand = false,
                this.infoNoOK = false,
                this.voucher =null,
                this.voucherAvaible = false,
                this.newDataAsignTD = '',
                this.newDataAsignCb = '',
                this.newDataAsignNB = '',
                this.newDataAsignNTB = '',
                this.loading = true,
                this.loadingReport = true,
                this.loadingVer = true,
                this.loadingAssign = true,
                this.loadingisValid = true,
                this.loadingChangeStep = true,
                this.loadingEndCita = true,
                this.loadingSentEmail = true,
                this.identificacion =null,
                this.items = []
            },
            ME0 (folioC, stepClick) {
                this.dialogIni = true
                this.folio = folioC
                this.step = stepClick
            },
            ME1 (folioC) {
                this.folio = folioC
                this.dialogUserAsigned = true
            },
            ME2 (folioC, emailC) {
                this.dialogEnd = true
                this.folio = folioC
                this.email = emailC
            },
            ME3 (folioC) {
                this.dialogUpdateDataUser = true
                this.folio = folioC
            }
        }
    }
</script>