<template>
  <div>
    <img :src="sdLogo" style="margin-top: 100px; margin-left: 50px; width: 200px" />
    <v-container class="h-100">
      <v-row class="text-center h-100 centroVertical">
        <v-col cols="12" md="4" class="loginCont" style="margin-top: 100px">
          <div class="d-flex flex-column">
            <p style="margin-bottom: 3px; font-weight: 600; color: #5f6b71">
              Iniciar Sesión
            </p>
            <p style="font-weight: 600; color: #5f6b71">
              Herramientas de devoluciones
            </p>
            <div class="contCorreo">
              <input
                type="text"
                v-model="user"
                class="inputUser required"
                placeholder="Correo electrónico"
                required
              />
            </div>
            <div class="contPass">
              <input
                type="password"
                v-model="pass"
                class="inputPass required"
                placeholder="Contraseña"
                @keypress.enter="logIn"
              />
            </div>
            <div
              class="alert alert-danger mb-none alert-dismissible fade"
              v-if="alert"
              role="alert"
            >
              {{ error }}
            </div>
            <div class="my-2" style="padding-top: 2em">
              <v-btn
                @click="logIn"
                class="btnIniciarSesion my-button"
                :loading="!status"
                >Ingresar</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style>
.loginCont {
  border-radius: 15px;
  align-self: flex-start;
  flex: 0 1 auto;
}
.h-100 {
  height: 100%;
}
.centroVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
}
.user {
  border: 0px solid #666;
  border-radius: 64px;
  height: 134px;
  width: 134px;
}

.contCorreo,
.contPass {
  background-color: #fff;
  color: gray;
  border: 1px solid #afafaf;
  border-radius: 5px;
  width: 340px;
  align-self: center;
  margin-top: 20px;
  font-size: 16px;
}

.mb-none {
  margin-bottom: -47px;
}

.inputPass,
.inputUser {
  border-radius: 0px 10px 10px 0px;
  padding: 10px;
  width: 100%;
}

.inputPass:focus,
.inputUser:focus {
  outline: none;
}

.contCorreo:focus-within,
.contPass:focus-within {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btnIniciarSesion {
  width: 340px;
  margin-top: 30px;
  height: 40px;
  font-size: 16px;
}

.letraNormal .v-btn__content {
  text-transform: none;
}
</style>

<script>
import loginService from "../services/login";
export default {
  name: "login",

  data: () => ({
    user: null,
    status: true,
    error: null,
    alert: false,
    pass: null,
    sdLogo: require("@/assets/imgs/logo-SD.png"),
  }),
  methods: {
    logIn() {
      this.status = false;
      loginService
        .login(this.user.toLowerCase(), this.pass)
        .then((res) => {
          if (res.status == true) {
            sessionStorage.setItem("dataUser", JSON.stringify(res.data));
            sessionStorage.setItem("tipo_user", res.data.role);
            sessionStorage.setItem("token", res.accesToken);
            window.location.href = "/inicio";
          } else {
            this.alert = true;
            this.status = true;
            this.error = res.message;
          }
        })
        .catch((err) => {
          this.alert = true;
          this.status = true;
          if (err == "Error: Network Error") {
            this.error = "No hay conexión con servidor";
          } else if (err == "Error: Request failed with status code 404") {
            this.error = "Correo o contraseña incorrecto";
          } else if (err == "Error: Request failed with status code 500") {
            this.error = "Reintentar en 1 minuto, Error 500";
          } else {
            this.error = err;
          }
        });
    },
  },
};
</script>
