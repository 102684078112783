<template>
  <div style="padding: 30px 50px 100px 50px">
    <div v-if="level == 0">
      <v-container>
        <v-card hover class="myCardClass" style="padding: 0px">
          <v-list-item style="padding: 0px">
            <v-list-item-content style="padding: 0px">
              <v-card-title style="padding: 0px">
                <div class="card-header">
                  <p class="header-text">Consultar información de devolución</p>
                </div>
              </v-card-title>
              <v-row style="padding: 15px 10px 30px 10px">
                <v-col cols="12" class="text-container">
                  <v-row>
                    <v-col cols="6">
                      <input
                        type="text"
                        placeholder="Folio de la cita"
                        required
                        v-model="folio"
                        class="my-input"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="text-container"
                  style="padding-top: 0px; margin-top: 10px"
                >
                  <v-row style="padding-top: 0px">
                    <v-col cols="6" style="padding-top: 0px">
                      <v-btn
                        @click="informacion"
                        :loading="!statusfolio"
                        class="my-button"
                        >Buscar</v-btn
                      >
                      <div v-if="errorinfomacion">
                        <div class="red--text" style="padding-top: 15px">
                          {{ errorinfomacion }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-container>
      <v-container>
        <v-card hover class="myCardClass" style="padding: 0px">
          <v-list-item style="padding: 0px">
            <v-list-item-content style="padding: 0px">
              <v-card-title style="padding: 0px">
                <div class="card-header">
                  <p class="header-text">Obtener reagendados por período</p>
                </div>
              </v-card-title>
              <v-row style="padding: 15px 10px 30px 10px">
                <v-row>
                  <v-col cols="6" sm="8" md="4">
                    <v-menu
                      v-model="fechaIni"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateIni"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateIni"
                        @input="fechaIni = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="8" md="4">
                    <v-menu
                      v-model="fechaFin"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFin"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFin"
                        @input="fechaFin = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-col cols="12" style="padding-top: 0px">
                  <v-col cols="12" style="padding-top: 0px">
                    <v-btn
                      @click="periodo"
                      :loading="!statusfecha"
                      class="my-button"
                      >Buscar</v-btn
                    >
                  </v-col>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-container>
    </div>
    <div v-else-if="level == 1">
      <v-container>
        <v-row>
          <v-col md="6">
            <v-card hover class="myCardClass" style="padding: 0px">
              <v-list-item style="padding: 0px">
                <v-list-item-content style="padding: 0px">
                  <v-card-title style="padding: 0px">
                    <div class="card-header">
                      <p class="header-text">Información del paciente</p>
                    </div>
                  </v-card-title>
                  <v-card-text style="padding-top: 15px">
                    <p>
                      <b>Nombre:</b> {{ info.cita.nombre }}
                      {{ info.cita.paterno }} {{ info.materno }}
                    </p>
                    <p>
                      <b>FechaNacimiento:</b> {{ info.cita.fechaNacimiento }}
                    </p>
                    <p><b>Correo:</b> {{ info.cita.correo }}</p>
                    <p style="margin-bottom: 4px !important">
                      <b>Celular:</b> {{ info.cita.telefonoCelular }}
                    </p>
                  </v-card-text>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card hover class="myCardClass" style="padding: 0px">
              <v-list-item style="padding: 0px">
                <v-list-item-content style="padding: 0px">
                  <v-card-title style="padding: 0px">
                    <div class="card-header">
                      <p class="header-text">Información de la cita</p>
                    </div>
                  </v-card-title>
                  <v-card-text style="padding-top: 15px">
                    <p>
                      <b class="mb-2">Sucursal:</b> {{ info.cita.sucursal }}
                    </p>
                    <p>
                      <b class="mb-2">Folio cita:</b>
                      {{ info.cita.idCitaSisPrev }}
                    </p>
                    <p><b class="mb-2">Fecha:</b> {{ info.cita.citaFecha }}</p>
                    <p><b class="mb-2">Hora:</b> {{ info.cita.citaHora }}</p>
                    <p style="margin-bottom: 4px !important">
                      <b>Monto:</b> {{ info.cita.montoTotal }}
                    </p>
                  </v-card-text>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-card hover class="myCardClass" style="padding: 0px">
          <v-list-item style="padding: 0px">
            <v-list-content style="padding: 0px; width: 100%;">
              <v-card-title style="padding: 0px">
                <div class="card-header">
                  <p class="header-text">Estudios reagendados</p>
                </div>
              </v-card-title>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">  Reagendo</th>
                    <th class="text-left">Folio Cita</th>
                    <th class="text-left">Nombre Estudio</th>
                    <th class="text-left">Fecha Anterior</th>
                    <th class="text-left">Hora Anterior</th>
                    <th class="text-left">Fecha Nueva</th>
                    <th class="text-left">Hora Nueva</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="estudio in info.estudios"
                    :key="estudio.idCitaSisPrev"
                  >
                    <td>{{ (estudio.createdAt).substr(0, 19).replace('T', ' ') }}</td>
                    <td>{{ estudio.idCitaSisPrev }}</td>
                    <td>{{ estudio.nombre }}</td>
                    <td>{{ estudio.fechaV }}</td>
                    <td>{{ estudio.horaV }}</td>
                    <td>{{ estudio.fechaN }}</td>
                    <td>{{ estudio.horaN }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-list-content>
          </v-list-item>
        </v-card>
        <br />
        <div v-if="infoPeriodo != null">
          <v-btn @click="regresarPeriodo" :loading="status" class="my-button"
            >Regresar</v-btn
          >
        </div>
        <div v-else>
          <v-btn @click="regresar" :loading="status" class="my-button"
            >Regresar</v-btn
          >
        </div>
      </v-container>
    </div>
    <div v-else-if="level == 2">
      <v-container>
        <v-row>
          <v-container>
            <v-card hover>
              <v-list-item>
                <v-list-item-content>
                  <v-card-title>
                    <v-row>
                      <v-row cols="6">
                        <v-col>
                          <b class="devoluciones-header">Citas reagendadas por dia</b>
                          <p class="fecha-header">Fecha: {{ dateIni }} / {{ dateFin }}</p>
                        </v-col>
                      </v-row>

                      <v-col cols="6" style="text-align: right">
                        <v-container style="vertical-align: middle">
                          <b>Total </b>
                          <b class="devoluciones-header"
                            >{{ infoPeriodo.reagendadas.estudios }}
                          </b>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-container>
          <v-container>
            <div v-if="type == 0">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px">
                      <div class="card-header">
                        <p class="header-text">Reagendadas por motivo</p>
                      </div>
                    </v-card-title>
                    <v-container>
                      <pie
                        :chart-data="graphmotivo"
                        class="chart"
                        :options="graphmotivo.options"
                      />
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
            <div v-if="type == 1">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px">
                      <div class="card-header">
                        <p class="header-text">Reagendadas por estudio</p>
                      </div>
                    </v-card-title>
                    <v-container>
                      <pie
                        :chart-data="graphestudio"
                        class="chart"
                        :options="graphestudio.options"
                      />
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
            <div v-else-if="type == 2">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px">
                      <div class="card-header">
                        <p class="header-text">Reagendadas por clínica</p>
                      </div>
                    </v-card-title>
                    <v-container>
                      <pie
                        :chart-data="graphclinica"
                        class="chart"
                        :options="graphclinica.options"
                      />
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </v-container>
          <v-container style="padding-top: 25px">
            <div class="d-flex justify-end">
              <v-btn dark color="#FFAB0B" @click="descargarexcel">
                <v-img
                  alt="arriba"
                  contain
                  src="../assets/imgs/iconos/download.png"
                  style="padding-right: 15px"
                />
                Descargar hoja de cálculo
              </v-btn>
            </div>
            <v-card hover>
              <v-tabs left>
                <v-tab @click="type = 0">Devoluciones por motivo</v-tab>
                <v-tab-item>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="data in infoPeriodo.motivos"
                      :key="data.nombre"
                    >
                      <v-expansion-panel-header>
                        <div style="display: flex">
                          <b>{{ data.nombre }}</b>
                          <b
                            class="header-text"
                            style="margin-left: auto; color: #6c90c0"
                            >{{ data.cantidad }}</b
                          >
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="data.cantidad == 0">
                          No tiene folios
                        </div>
                        <div v-else>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th class="text-left">Folio Cita</th>
                                <th class="text-left">Nombre Estudio</th>
                                <th class="text-left">Fecha Anterior</th>
                                <th class="text-left">Hora Anterior</th>
                                <th class="text-left">Fecha Nueva</th>
                                <th class="text-left">Hora Nueva</th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="cita in data.citas" :key="cita.cita">
                                <td>{{ cita.cita }}</td>
                                <td>{{ cita.nombre }}</td>
                                <td>{{ cita.fechaV }}</td>
                                <td>{{ cita.horaV }}</td>
                                <td>{{ cita.fechaN }}</td>
                                <td>{{ cita.horaN }}</td>
                                <td>
                                  <v-btn
                                    @click="verMas(cita.cita)"
                                    class="my-button"
                                    style="background-color: #45d8ba !important"
                                  >
                                    Ver más
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
                <v-tab @click="type = 1">Devoluciones por estudio</v-tab>
                <v-tab-item>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="data in infoPeriodo.estudios"
                      :key="data.nombre"
                    >
                      <v-expansion-panel-header>
                        <div style="display: flex">
                          <b>{{ data.nombre }}</b>
                          <b
                            class="header-text"
                            style="margin-left: auto; color: #6c90c0"
                            >{{ data.cantidad }}</b
                          >
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="data.cantidad == 0">
                          No tiene folios
                        </div>
                        <div v-else>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th class="text-left">Folio Cita</th>
                                <th class="text-left">Nombre Estudio</th>
                                <th class="text-left">Fecha Anterior</th>
                                <th class="text-left">Hora Anterior</th>
                                <th class="text-left">Fecha Nueva</th>
                                <th class="text-left">Hora Nueva</th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="cita in data.citas" :key="cita.cita">
                                <td>{{ cita.cita }}</td>
                                <td>{{ cita.nombre }}</td>
                                <td>{{ cita.fechaV }}</td>
                                <td>{{ cita.horaV }}</td>
                                <td>{{ cita.fechaN }}</td>
                                <td>{{ cita.horaN }}</td>
                                <td>
                                  <v-btn
                                    @click="verMas(cita.cita)"
                                    class="my-button"
                                    style="background-color: #45d8ba !important"
                                  >
                                    Ver más
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
                <v-tab @click="type = 2">Devoluciones por clínica</v-tab>
                <v-tab-item>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="data in infoPeriodo.sucursales"
                      :key="data.nombre"
                    >
                      <v-expansion-panel-header>
                        <div style="display: flex">
                          <b>{{ data.nombre }}</b>
                          <b
                            class="header-text"
                            style="margin-left: auto; color: #6c90c0"
                            >{{ data.cantidad }}</b
                          >
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="data.cantidad == 0">
                          No tiene Sucursales
                        </div>
                        <div v-else>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th class="text-left">Folio Cita</th>
                                <th class="text-left">Nombre Estudio</th>
                                <th class="text-left">Fecha Anterior</th>
                                <th class="text-left">Hora Anterior</th>
                                <th class="text-left">Fecha Nueva</th>
                                <th class="text-left">Hora Nueva</th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="cita in data.citas" :key="cita.cita">
                                <td>{{ cita.cita }}</td>
                                <td>{{ cita.nombre }}</td>
                                <td>{{ cita.fechaV }}</td>
                                <td>{{ cita.horaV }}</td>
                                <td>{{ cita.fechaN }}</td>
                                <td>{{ cita.horaN }}</td>
                                <td>
                                  <v-btn
                                    @click="verMas(cita.cita)"
                                    class="my-button"
                                    style="background-color: #45d8ba !important"
                                  >
                                    Ver más
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-container>
          <v-container>
            <v-btn @click="regresar" :loading="status" class="my-button"
              >Regresar</v-btn
            >
          </v-container>
        </v-row>
      </v-container>
    </div>
    <ScrollTopArrow></ScrollTopArrow>
  </div>
</template>

<style>
.fecha-header {
  padding-top: 15px;
  font-size: 15px;
}
</style>

<script>
import reagendadasService from "../services/reagendar";
import Pie from "../plugins/Pie";
import ScrollTopArrow from "./ScrollTopArrow";

export default {
  components: {
    Pie,
    ScrollTopArrow,
  },
  data: () => ({
    dateIni: new Date().toISOString().substr(0, 10),
    dateFin: new Date().toISOString().substr(0, 10),
    status: false,
    statusfolio: true,
    statusfecha: true,
    errorinfomacion: null,
    errorperiodo: null,
    alert: false,
    folio: null,
    fechaIni: false,
    fechaFin: false,
    level: 0,
    type: 0,
    graphmotivo: null,
    graphestudio: null,
    graphclinica: null,
    colors: [
      "#DF0101",
      "#DF3A01",
      "#DF7401",
      "#DBA901",
      "#D7DF01",
      "#A5DF00",
      "#74DF00",
      "#3ADF00",
      "#01DF01",
      "#01DF74",
      "#01DFD7",
      "#01A9DB",
      "#0174DF",
      "#013ADF",
      "#0101DF",
      "#3A01DF",
      "#3104B4",
      "#7401DF",
      "#A901DB",
      "#DF01D7",
      "#DF01A5",
      "#DF0174",
      "#8A0829",
      "#FF0040",
      "#F5BCA9",
      "#CED8F6",
      "#CED8F6",
    ],
    info: null,
    infoPeriodo: null,
  }),
  methods: {
    informacion() {
      this.statusfolio = false;
      reagendadasService.getReagendada(this.folio).then((res) => {
        if (res.status) {
          this.info = res;
          this.level = 1;
          this.errorinfomacion = null;
          this.statusfolio = true;
        } else {
          this.errorinfomacion = res.message;
          this.statusfolio = true;
        }
      });
    },
    periodo() {
      this.statusfecha = false;
      reagendadasService
        .getReagendadaPeriodo(this.dateIni, this.dateFin)
        .then((res) => {
          if (res.status) {
            this.infoPeriodo = res;
            this.level = 2;
            this.errorperiodo = null;
            this.fillGrapMotivos();
            this.fillGraphEstudios();
            this.fillGraphClinica();
            this.statusfecha = true;
          } else {
            this.errorperiodo = res.message;
            this.statusfecha = true;
          }
        });
    },
    regresar() {
      this.level = 0;
    },
    regresarPeriodo() {
      this.level = 2;
    },
    fillGrapMotivos() {
      this.graphmotivo = {
        labels: this.infoPeriodo.graficas.motivos.name,
        datasets: [
          {
            label: "Grafica devoluciones por motivos",
            data: this.infoPeriodo.graficas.motivos.values,
            backgroundColor: this.colors,
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "left",
          },
        },
      };
    },
    fillGraphEstudios() {
      this.graphestudio = {
        labels: this.infoPeriodo.graficas.estudios.name,
        datasets: [
          {
            label: "Grafica devoluciones por estudios",
            data: this.infoPeriodo.graficas.estudios.values,
            backgroundColor: this.colors,
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "left",
          },
        },
      };
    },
    fillGraphClinica() {
      this.graphclinica = {
        labels: this.infoPeriodo.graficas.clinicas.name,
        datasets: [
          {
            label: "Grafica devoluciones por clinicas",
            data: this.infoPeriodo.graficas.clinicas.values,
            backgroundColor: this.colors,
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "left",
          },
        },
      };
    },
    verMas(folio) {
      reagendadasService.getReagendada(folio).then((res) => {
        if (res.status) {
          this.info = res;
          this.level = 1;
        }
      });
    },
    descargarexcel() {
      reagendadasService
        .getReagendadaPeriodoExcel(this.dateIni, this.dateFin)
        .then((res) => {
          if (res.status) {
            reagendadasService.getFile(res.url, res.name);
          }
        });
    },
  },
};
</script>
