import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Inicio from "../views/Inicio.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/inicio",
    name: "Inicio",
    component: Inicio,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    name: "NotFound",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const DEFAULT_TITLE = "Devoluciones - Herramientas";

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  console.log(token);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token != null) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
