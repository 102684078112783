<template>
  <div class="inicio">
    <div class="tabs d-flex px5 tab-class">
              <div class="tab borderRight" @click="activar=0" :class="{'activo': activar==0}">
                <img :src="home" v-if="activar!=0">
                <img :src="homeOn" v-if="activar==0">
                <h4 style="margin-left: 10px; font-size: 14px; font-weight: 600"> Home</h4>
              </div>
              <div class="tab" @click="activar=1" :class="{'activo': activar==1}">
                <img :src="devolucion" v-if="activar!=1">
                <img :src="devolucionOn" v-if="activar==1">
                <h4 style="margin-left: 10px; font-size: 14px; font-weight: 600"> Devoluciones LP</h4>
              </div>
              <div class="tab borderLeft" @click="activar=2" :class="{'activo': activar==2}">
                <img :src="reagenda" v-if="activar!=2">
                <img :src="reagendaOn" v-if="activar==2">
                <h4 style="margin-left: 10px; font-size: 14px; font-weight: 600">Reagendados LP</h4>
              </div>
              <div class="tab borderLeft" @click="activar=3" :class="{'activo': activar==3}">
                <img :src="oxxo" v-if="activar!=3">
                <img :src="oxxoOn" v-if="activar==3">
                <h4 style="margin-left: 10px; font-size: 14px; font-weight: 600">OXXO PAY</h4>
              </div>
              <div v-if="user == 1">
                <div class="tab borderLeft" @click="activar=4" :class="{'activo': activar==4}">
                  <img :src="bitaco" v-if="activar!=4">
                  <img :src="bitacoOn" v-if="activar==4">
                  <h4 style="margin-left: 10px; font-size: 14px; font-weight: 600">Bitacora</h4>
                </div>
              </div>
            </div>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="noBoxShadow" style="background-color: transparent; margin-top: 30px">
            <dashboard v-if="activar==0"/>
            <devoluciones v-else-if="activar==1"/>
            <reagendados v-else-if="activar==2"/>
            <oxxo v-else-if="activar==3"/>
            <bitacora v-else-if="activar==4"/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style>
 .inicio{
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f2f7fb;
    overflow-x: hidden;
    height: 100%;
 }
 .tabs{
   flex-direction: row;
   margin-bottom: -2px;
 }
 .borderRight{
   border-right: 1px solid #f6f6fb;
 }
 .borderLeft{
   border-left: 1px solid #f6f6fb;
 }
 .tab{
   padding: 10px 30px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #AFAFAF;
   cursor: pointer;
   font-weight:normal!important;
   font-size: 14px;
   position: relative;
   top: 2px;
 }
 .tab:hover{
   opacity: 0.7;
 }
 .tab h4{
   font-weight: normal;
   font-size: 18px;
 }
 .tab.activo{
   border-bottom: 3px solid #a6c2e0;
   border-radius: 2px 2px 0 0;
   color: #666;
   cursor: default;
 }
 .noBoxShadow{
   box-shadow: none !important;
 }
 .tab-class{
   position: fixed;
   width: 100%;
   align-content: flex-start;
   background-color: white;
   box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
   z-index: 2;
 }
</style>
<script>
import Dashboard from '../components/Dashboard'
import Devoluciones from '../components/Devoluciones'
import Reagendados from '../components/Reagendados'
import Bitacora from '../components/Log.vue'
import Oxxo from '../components/Oxxo.vue'

export default {
  name: "Inicio",
  components: {
    Dashboard,
    Devoluciones,
    Reagendados,
    Bitacora,
    Oxxo
  },
  data: () => ({
    activar:0,
    home: require('@/assets/imgs/iconos/home-outpressing.png'),
    homeOn: require('@/assets/imgs/iconos/home-pressed.png'),
    left: require('@/assets/imgs/iconos/arrow-left-outpressing.png'),
    leftOn: require('@/assets/imgs/iconos/arrow-left-pressed.png'),
    calendar: require('@/assets/imgs/iconos/calendar-outpressing.png'),
    calendarOn: require('@/assets/imgs/iconos/calendar-pressed.png'),
    user: sessionStorage.getItem("tipo_user")
  })
}
</script>