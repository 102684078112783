<template>
  <ScrollTopComponent>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="#6c90c0"
      >
        <v-img
            alt="arriba"
            contain
            src="../assets/imgs/iconos/arrow-up.png"
        />
      </v-btn>
  </ScrollTopComponent>
</template>

<script>
import ScrollTopComponent from './ScrollTopComponent'

export default {
  components: {
    ScrollTopComponent
  }
}
</script>

<style>
.btn {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.55);
    padding-top: 27px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}
</style>