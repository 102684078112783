<template>
  <div style="padding: 30px 50px 100px 50px">
      <v-container>
          <v-row>
              <v-container>
                  <v-card hover>
                      <v-list-item>
                          <v-list-item-content>
                              <v-card-title>
                                  Registro de errores
                              </v-card-title>
                              <v-row style="padding: 15px 10px 30px 10px">
                                <v-row>
                                <v-col cols="6" sm="8" md="4">
                                    <v-menu
                                    v-model="fechaIni"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="dateIni"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateIni"
                                        @input="fechaIni = false"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" sm="8" md="4">
                                    <v-menu
                                    v-model="fechaFin"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="dateFin"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateFin"
                                        @input="fechaFin = false"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                </v-row>
                                <v-col cols="12" style="padding-top: 0px">
                                <v-col cols="12" style="padding-top: 0px;">
                                    <v-btn
                                    @click="fill"
                                    :loading="!status"
                                    class="my-button"
                                    >Buscar</v-btn
                                    >
                                    <div v-if="error">
                                    <div class="red--text" style="padding-top: 15px">{{error}}</div>
                                    </div>
                                </v-col>
                                </v-col>
                              </v-row>
                          </v-list-item-content>
                      </v-list-item>
                  </v-card>
              </v-container>
              <v-container>
                  <v-row>
                    <v-col cols="5">
                        <v-card hover>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-card-title>
                                        Grafica de errores
                                    </v-card-title>
                                    <v-container>
                                        <pie
                                            :chart-data="graph"
                                            class="chart"
                                            :options="graph.options"
                                        />
                                    </v-container>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card hover>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-container>
                                        <b>Devolucion</b>
                                        <div v-if="devolucion.log.size == 0">
                                            Sin errores registrados
                                        </div>
                                        <div v-else>
                                            <v-simple-table>
                                                <thead>
                                                    <tr>
                                                        <th>Cantidad</th>
                                                        <th>Nombre</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="log in devolucion.agrupacion" :key="log.temp">
                                                        <td>{{ log.count }}</td>
                                                        <td>{{ log._id }}</td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </div>
                                        <b>Reagendar</b>
                                        <div v-if="reagendar.log.size == 0">
                                            Sin errores registrados
                                        </div>
                                        <div v-else>
                                            <v-simple-table>
                                                <thead>
                                                    <tr>
                                                        <th>Cantidad</th>
                                                        <th>Nombre</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="log in reagendar.agrupacion" :key="log.temp">
                                                        <td>{{ log.count }}</td>
                                                        <td>{{ log._id }}</td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </div>
                                    </v-container>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                  </v-row>
              </v-container>
              <v-container>
                  <v-card hover>
                      <v-tabs left>
                          <v-tab>Devolucion</v-tab>
                          <v-tab-item>
                              <div v-if="devolucion.log.size == 0">
                                  Sin errores registrados
                              </div>
                              <div v-else>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Cita</th>
                                            <th>Metodo</th>
                                            <th>Descripcion</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="log in devolucion.log.data" :key="log._id">
                                            <td>
                                                <div v-if="log.tipo == 1">
                                                    <v-chip
                                                      class="ma-2"
                                                      color="primary"
                                                    >
                                                      Info
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip
                                                      class="ma-2"
                                                      color="red"
                                                      text-color="white"
                                                    >
                                                      Error
                                                    </v-chip>
                                                </div>
                                            </td>
                                            <td>{{ log.cita }}</td>
                                            <td>{{ log.metodo }}</td>
                                            <td>{{ log.descripcion }}</td>
                                            <td>{{ (log.createdAt).substr(0, 19).replace('T', ' ') }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                              </div>
                          </v-tab-item>
                          <v-tab>Reagendar</v-tab>
                          <v-tab-item>
                              <div v-if="reagendar.log.size == 0">
                                  Sin errores registrados
                              </div>
                              <div v-else>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Cita</th>
                                            <th>Metodo</th>
                                            <th>Descripcion</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="log in reagendar.log.data" :key="log._id">
                                            <td>
                                                <div v-if="log.tipo == 1">
                                                    <v-chip
                                                      class="ma-2"
                                                      color="primary"
                                                    >
                                                      Info
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip
                                                      class="ma-2"
                                                      color="red"
                                                      text-color="white"
                                                    >
                                                      Error
                                                    </v-chip>
                                                </div>
                                            </td>
                                            <td>{{ log.cita }}</td>
                                            <td>{{ log.metodo }}</td>
                                            <td>{{ log.descripcion }}</td>
                                            <td>{{ (log.createdAt).substr(0, 19).replace('T', ' ') }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                              </div>
                          </v-tab-item>
                      </v-tabs>
                  </v-card>
              </v-container>
          </v-row>
      </v-container>
    <ScrollTopArrow></ScrollTopArrow>
  </div>
</template>

<script>
import serviceLog from "../services/logs";
import Pie from "../plugins/Pie";
import ScrollTopArrow from "./ScrollTopArrow"

export default {
    components: {
        Pie,
        ScrollTopArrow
    },
    data: () => ({
        status: true,
        error: null,
        colors: [
        "#DF0101",
        "#CED8F6"
        ],
        graph: null,
        devolucion: null,
        reagendar: null,
        dateIni: new Date().toISOString().substr(0, 10),
        dateFin: new Date().toISOString().substr(0, 10),
    }),
    mounted() {
        this.fillData()
    },
    methods: {
        fillData() {
            serviceLog.getLogReporte(this.dateIni, this.dateFin).then((res) => {
                console.log(res)

                this.devolucion = res.devolucion
                this.reagendar = res.reagendar

                this.graph = {
                    labels: res.grafica.name,
                    datasets: [
                        {
                            label: "Grafica de errores",
                            data: res.grafica.data,
                            backgroundColor: this.colors
                        }
                    ],
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            position: "left"
                        }
                    }
                }
            });
        },
        fill() {
            serviceLog.getLogReporte(this.dateIni, this.dateFin).then((res) => {
                console.log(res)

                this.devolucion = res.devolucion
                this.reagendar = res.reagendar

                this.graph = {
                    labels: res.grafica.name,
                    datasets: [
                        {
                            label: "Grafica de errores",
                            data: res.grafica.data,
                            backgroundColor: this.colors
                        }
                    ],
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            position: "left"
                        }
                    }
                }
            });
        }
    }
}
</script>
