<template>
  <div class="inicio">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
          <div align="center">
            <h1>Pagina no encontrada</h1>
            <v-btn href="/inicio" class="my-button">Ir a inicio</v-btn>
          </div>
      </v-row>
    </v-container>
  </div>
</template>

<style>
.inicio {
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f2f7fb;
  overflow-x: hidden;
  height: 100%;
}
</style>
<script>
export default {
  name: "Not Fount",
};
</script>
