<template>
  <div class="fondo">
    <ingresar />
  </div>
</template>

<style>
  .fondo {
    background-image: url("../assets/imgs/fondos/login-background.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
    overflow-x: hidden;
    height: 100%;
  }
</style>
<script>
import Ingresar from '@/components/Ingresar.vue'

export default {
  name: 'Home',
  components: {
    Ingresar
  }
}
</script>
