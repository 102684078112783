import api from './api'
let service = {}

service.getLogdevolucion = function (fechaIni, fehaFin) {
    return api.get('/monitor/devolucion/fechas', {
        params: {
            fechaInicio: fechaIni,
            fechaFinal: fehaFin
        }
    }).then(res => res.data)
}

service.getLogReagendar = function (fechaIni, fehaFin) {
    return api.get('/monitor/reagendar/fechas', {
        params: {
            fechaInicio: fechaIni,
            fechaFinal: fehaFin
        }
    }).then(res => res.data)
}

service.getLogGroupdevolucion = function (fechaIni, fehaFin) {
    return api.get('/monitor/devolucion/groups', {
        params: {
            fechaInicio: fechaIni,
            fechaFinal: fehaFin
        }
    }).then(res => res.data)
}

service.getLogGroupReagendar = function (fechaIni, fehaFin) {
    return api.get('/monitor/reagendar/groups', {
        params: {
            fechaInicio: fechaIni,
            fechaFinal: fehaFin
        }
    }).then(res => res.data)
}

service.getLogReporte = function (fechaIni, fehaFin) {
    return api.get('/monitor/reporte', {
        params: {
            fechaInicio: fechaIni,
            fechaFinal: fehaFin
        }
    }).then(res => res.data)
}

export default service
