<template>
  <v-app-bar
  app
  color="white"
  v-if="$router.history.current.path != '/'"
  class="noBoxShadow"
  >
    <div style="padding: 35px"></div>
    <v-img
      alt="La salud es para todos"
      class="ml-4 logoSD"
      contain
      src="../assets/imgs/logo-SD.png"
      max-width="200"
    />
    
    <v-spacer></v-spacer>

    <v-btn v-show="ruta=='/inicio'"
      text
      @click="cerrarSesion"
    >
      <v-img
        alt="Usuario"
        class="mr-2"
        contain
        min-width="9"
        src="../assets/imgs/user.png"
        width="30"
      />
      
      <span class="mr-2 letraNormal">Cerrar sesión</span>
    </v-btn>

    <div style="padding: 45px"></div>
  </v-app-bar>
</template>

<style>
  .logoSD{
    position: relative;
    height: 70%;
  }
  .letraNormal{
    text-transform: initial;
  }
  .v-toolbar__content{
    border-bottom: 1px solid #f6f6fb;
  }
</style>

<script>
export default {
  name: "Navbar",
  data: () => ({
    ruta:window.location.pathname
  }),
  methods:{
    cerrarSesion(){
      window.location.href = '/'
      sessionStorage.clear()
    },
    currentRouteName() {
      return this.$route.currentroute;
    }
  }
}
</script>