<template>
  <div style="padding: 30px 50px 100px 50px">
    <div v-if="level == 0">
      <v-container>
        <v-card hover class="myCardClass">
          <v-list-item style="padding: 0px">
            <v-list-item-content style="padding: 0px">
              <v-card-title style="padding: 0px">
                <div class="card-header">
                  <p class="header-text">Consultar información de devolución</p>
                </div>
              </v-card-title>
              <v-row style="padding: 15px 10px 30px 10px">
                <v-col cols="12" class="text-container">
                  <v-row>
                    <v-col cols="6">
                      <input
                        type="text"
                        placeholder="Cita o folio devolución"
                        required
                        v-model="folio"
                        class="my-input"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  class="text-container"
                  style="padding-top: 0px; margin-top: 10px"
                >
                  <v-row style="padding-top: 0px">
                    <v-col cols="6" style="padding-top: 0px">
                      <v-btn
                        @click="informacion"
                        :loading="!statusfolio"
                        class="my-button"
                        >Buscar</v-btn
                      >
                      <div v-if="errorinfomacion">
                        <div class="red--text" style="padding-top: 15px">{{errorinfomacion}}</div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-container>
      <v-container>
        <v-card hover class="myCardClass" style="padding: 0px">
          <v-list-item style="padding: 0px">
            <v-list-item-content style="padding: 0px">
              <v-card-title style="padding: 0px">
                <div class="card-header">
                  <p class="header-text">Obtener devoluciones por periodo</p>
                </div>
              </v-card-title>
              <v-row style="padding: 15px 10px 30px 10px">
                <v-row>
                  <v-col cols="6" sm="8" md="4">
                    <v-menu
                      v-model="fechaIni"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateIni"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateIni"
                        @input="fechaIni = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" sm="8" md="4">
                    <v-menu
                      v-model="fechaFin"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFin"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFin"
                        @input="fechaFin = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-col cols="12" style="padding-top: 0px">
                  <v-col cols="12" style="padding-top: 0px;">
                    <v-btn
                      @click="periodo"
                      :loading="!statusfecha"
                      class="my-button"
                      >Buscar</v-btn
                    >
                    <div v-if="errorperiodo">
                      <div class="red--text" style="padding-top: 15px">{{errorperiodo}}</div>
                    </div>
                  </v-col>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-container>
    </div>
    <div v-else-if="level == 1">
      <v-row>
        <v-container>
          <v-row>
            <v-col md="6">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px">
                      <div class="card-header">
                        <p class="header-text">Información del paciente</p>
                      </div>
                    </v-card-title>
                    <v-card-text style="padding-top: 15px">
                      <p><b>Nombre:</b> {{ info.nombre }} {{ info.paterno }}
                      {{ info.materno }}</p>
                      <p><b>FechaNacimiento:</b> {{ info.fechaNacimiento }}</p>
                      <p><b>Correo:</b> {{ info.correo }}</p>
                      <p style="margin-bottom: 4px !important"><b>Celular:</b> {{ info.telefonoCelular }}</p>
                    </v-card-text>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col md="6">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px">
                      <div class="card-header">
                        <p class="header-text">Información de la cita</p>
                      </div>
                    </v-card-title>
                    <v-card-text style="padding-top: 15px">
                      <p><b class="mb-2">Sucursal:</b> {{ info.sucursal }}</p>
                      <p><b class="mb-2">Folio cita:</b> {{ info.idCitaSisPrev }}</p>
                      <p><b class="mb-2">Estudio:</b> {{ info.subEstudioVal }}</p>
                      <p><b class="mb-2">Fecha:</b> {{ info.citaFecha }}</p>
                      <p><b class="mb-2">Hora:</b> {{ info.citaHora }}</p>
                      <p style="margin-bottom: 4px !important"><b>Monto:</b> {{ info.montoTotal }}</p>
                    </v-card-text>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-container>
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-card-title style="padding: 0px">
                  <div class="card-header">
                    <p class="header-text">Información de la devolución</p>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            ><p><b class="mb-2">Folio devolucion:</b>
                            {{ info.folioCancelacion }}</p></v-list-item-title
                          >
                          <div>
                            <div v-if="info.createdAt">
                              <p><v-list-item-title
                                ><b class="mb-2">Fecha:</b>
                                {{ (info.createdAt).substr(0, 19).replace('T', ' ') }}</v-list-item-title
                              ></p>
                            </div>
                            <div v-else>
                              <p><v-list-item-title
                                ><b class="mb-2">Fecha:</b>
                                {{ info.fecha }}</v-list-item-title
                              ></p>
                            </div>
                          </div>
                          <v-list-item-title
                            ><p><b class="mb-2">Motivo:</b>
                            {{ info.motivoCancelacion }}</p></v-list-item-title
                          >
                          <div>
                            <div v-if="info.idDevolucion">
                              <p><v-list-item-title
                                ><b class="mb-2">Estado:</b> Devolucion realizada con
                                exito</v-list-item-title
                              ></p>
                            </div>
                            <div v-else>
                              <div v-if="info.status == 1">
                                <p><v-list-item-title
                                  ><b class="mb-2">Estado:</b> Pendiente</v-list-item-title
                                ></p>
                              </div>
                              <div v-else>
                                <div v-if="info.status == 'true'">
                                  <div
                                    v-if="
                                      info.mensaje ==
                                      'La cita ya cuenta con una devolución'
                                    "
                                  >
                                    <p><v-list-item-title
                                      ><b class="mb-2">Estado:</b> Ya tiene una
                                      devolucion</v-list-item-title
                                    ></p>
                                  </div>
                                  <div v-else>
                                    <p><v-list-item-title
                                      ><b class="mb-2">Estado:</b>
                                      {{ info.mensaje }}</v-list-item-title
                                    ></p>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-if="info.mensaje == 'La cita ya cuenta con una devolución'">
                                    <p><v-list-item-title
                                      ><b class="mb-2">Estado:</b> Ya tiene una
                                      devolucion</v-list-item-title
                                    ></p>
                                  </div>
                                  <div v-else>
                                    <div v-if="info.devolucion">
                                      <p><v-list-item-title
                                        ><b class="mb-2">Estado:</b> Devolucion realizada con
                                        exito</v-list-item-title
                                      ></p>
                                    </div>
                                    <div v-else>
                                      <p><v-list-item-title
                                        ><b class="mb-2">Estado:</b> Devolucion no
                                        realizada</v-list-item-title
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div v-if="info.plataforma == 1">
                              <p><v-list-item-title
                                ><b class="mb-2">Plataforma:</b> Web</v-list-item-title
                              ></p>
                            </div>
                            <div v-else>
                              <p><v-list-item-title
                                ><b class="mb-2">Plataforma:</b> WhatsApp</v-list-item-title
                              ></p>
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <div>
                            <div v-if="info.idTipoPago != 4">
                              <div v-if="info.mismoDia == 0">
                                <p><v-list-item-title
                                  ><b class="mb-2">Se realizo el dia de la cita:</b>
                                  No</v-list-item-title
                                ></p>
                              </div>
                              <div v-else>
                                <p><v-list-item-title
                                  ><b class="mb-2">Se realizo el dia de la cita:</b>
                                  Si</v-list-item-title
                                ></p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div v-if="info.idTipoPago == 3">
                              <div v-if="info.idPaquete == 1">
                                <p><v-list-item-title
                                  ><b class="mb-2">Medio de pago:</b>
                                  Conekta</v-list-item-title
                                ></p>
                              </div>
                              <div  v-else-if="info.idPaquete == 3">
                                <p><v-list-item-title
                                  ><b class="mb-2">Medio de pago:</b>
                                  Billpocket</v-list-item-title
                                ></p>
                              </div>
                              <div v-else-if="info.idPaquete == 5">
                                <p><v-list-item-title
                                  ><b class="mb-2">Medio de pago:</b>
                                  Santandar</v-list-item-title
                                ></p>
                              </div>
                            </div>
                            <div v-else>
                              <div v-if="info.idTipoPago == 4">
                                <p><v-list-item-title
                                  ><b class="mb-2">Medio de pago:</b> OXXO
                                  PAY</v-list-item-title
                                ></p>
                              </div>
                              <div v-else>
                                <div v-if="info.idTipoPago == 5">
                                  <p><v-list-item-title
                                    ><b class="mb-2">Medio de pago:</b> COPPEL
                                    PAY</v-list-item-title
                                  ></p>
                                </div>
                                <div v-else>
                                  <p><v-list-item-title
                                    ><b class="mb-2">Medio de pago:</b>
                                    N/A</v-list-item-title
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div v-if="info.idDevolucion">
                              <p><v-list-item-title
                                ><b class="mb-2">idDevolucion:</b>
                                {{ info.idDevolucion }}</v-list-item-title
                              ></p>
                            </div>
                            <div v-else>
                              <div v-if="info.idTipoPago == 4">
                                <div v-if="info.devolucion">
                                  <p><v-list-item-title
                                    ><b class="mb-2">Devolucion realizada:</b> SI</v-list-item-title
                                  ></p>
                                </div>
                                <div v-else>
                                  <p><v-list-item-title
                                    ><b class="mb-2">Devolucion realizada:</b> NO <v-btn @click="oxxo" :loading="status" class="my-button">Confirmar devolucion</v-btn></v-list-item-title
                                  ></p>
                                </div>
                              </div>
                              <div v-else>
                                <p><v-list-item-title
                                  ><b class="mb-2">idDevolucion:</b> No
                                  disponible</v-list-item-title
                                ></p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div v-if="info.idOrdenPago">
                              <p>
                                <v-list-item-title>
                                  <b class="mb-2">idOrdenPago:</b> {{info.idOrdenPago}}
                                </v-list-item-title>
                              </p>
                            </div>
                            <div v-else>
                              <p>
                                <v-list-item-title>
                                  <b class="mb-2">idOrdenPago:</b> No cuenta con IdOrdenPago
                                </v-list-item-title>
                              </p>
                            </div>
                          </div>
                          <div>
                            <div v-if="info.mismoDia == 1">
                              <div v-if="info.status == 1">
                                <p><v-list-item-title
                                  ><b class="mb-2">Devolucion realizada en:</b> No se realiza
                                  aun</v-list-item-title
                                ></p>
                              </div>
                              <div v-else>
                                <div v-if="info.status == true">
                                  <p><v-list-item-title
                                    ><b class="mb-2">Devolucion realizada en:</b> Cron</v-list-item-title></p>
                                </div>
                                <div v-else>
                                  <p><v-list-item-title
                                    ><b class="mb-2">Devolucion realizada en:</b> Cron</v-list-item-title
                                  ></p>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <p><v-list-item-title
                                ><b class="mb-2">Devolucion realizada en:</b>
                                LP</v-list-item-title
                              ></p>
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-row>
          <div v-if="info.idTipoPago == 4">
            <v-row>
              <v-container>
                <v-card hover class="myCardClass" style="padding: 0px">
                  <v-list-item style="padding: 0px">
                    <v-list-item-content style="padding: 0px">
                      <v-card-title style="padding: 0px">
                        <div class="card-header">
                          <p class="header-text">Información formulario</p>
                        </div>
                      </v-card-title>
                      <v-card-text style="padding-top: 15px">
                        <p><b class="mb-2">Banco:</b> {{ info.banco }}</p>
                        <p><b class="mb-2">Numero tarjeta:</b> {{ info.numero_tarjeta }}</p>
                        <p><b class="mb-2">Clabe interbancaria:</b> {{ info.clabe_interbancaria }}</p>
                        <p><b class="mb-2">Correo Electronico:</b> {{ info.correo_form }}</p>
                        <v-img alt="LP" class="mr-2" contain min-width="32" v-bind:src="`${info.ife_url}`" width="480"/>
                      </v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-container>
            </v-row>
          </div>
        </v-container>
        <div v-if="infoPeriodo != null">
          <v-container>
            <v-btn @click="regresarPeriodo" :loading="status" class="my-button" style="margin-top: 40px"
              >Regresar</v-btn
            >
          </v-container>
        </div>
        <div v-else>
          <v-container>
            <v-btn @click="regresar" :loading="status" class="my-button" style="margin-top: 40px"
              >Regresar</v-btn
            >
          </v-container>
        </div>
      </v-row>
    </div>
    <div v-else-if="level == 2">
      <v-container>
        <v-row>
          <v-container>
            <v-card hover>
              <v-list-item>
                <v-list-item-content>
                  <v-card-title>
                    <v-row>
                      <v-row cols="6">
                        <v-col>
                          <b class="devoluciones-header">Devoluciones por dia</b>
                          <p class="fecha-header">Fecha: {{dateIni}} / {{dateFin}}</p>
                        </v-col>
                      </v-row>

                      <v-col cols="6" style="text-align: right">
                        <v-container style="vertical-align: middle">
                          <b>Total </b>
                          <b class="devoluciones-header">{{ infoPeriodo.devoluciones }}</b>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-container>
          <v-container>
            <v-row>
              <v-col>
                <v-card hover>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card-title>
                        <v-row>
                          <v-col md="2"><v-img alt="LP" class="mr-2" contain min-width="32" src="../assets/imgs/web32.png" width="32"/></v-col>
                          <v-col style="text-align: left"><b>LP</b></v-col>
                          <v-col style="text-align: right"><b>Total </b><b class="devoluciones-header">{{ infoPeriodo.devolucionesResumen.lp }}</b></v-col>
                        </v-row>
                      </v-card-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col>
                <v-card hover>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card-title>
                        <v-row>
                          <v-col md="2"><v-img alt="LP" class="mr-2" contain min-width="32" src="../assets/imgs/robot32.png" width="32"/></v-col>
                          <v-col style="text-align: left"><b>Cron</b></v-col>
                          <v-col style="text-align: right"><b>Total </b><b class="devoluciones-header">{{ infoPeriodo.devolucionesResumen.cron }}</b></v-col>
                        </v-row>
                      </v-card-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col>
                <v-card hover>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card-title>
                        <v-row>
                          <v-col md="2"><v-img alt="Asesor" class="mr-2" contain min-width="32" src="../assets/imgs/asesor32.png" width="32"/></v-col>
                          <v-col style="text-align: left"><b>Asesor</b></v-col>
                          <v-col style="text-align: right"><b>Total </b><b class="devoluciones-header">{{ infoPeriodo.devolucionesResumen.asesor }}</b></v-col>
                        </v-row>
                      </v-card-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col>
                <v-card hover>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card-title>
                        <v-row>
                          <v-col md="2"><v-img alt="LP" class="mr-2" contain min-width="32" src="../assets/imgs/wts32.png" width="32"/></v-col>
                          <v-col style="text-align: left"><b>WhatsApp</b></v-col>
                          <v-col style="text-align: right"><b>Total </b><b class="devoluciones-header">{{ infoPeriodo.devolucionesResumen.whatsapp }}</b></v-col>
                        </v-row>
                      </v-card-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col>
                <v-card hover>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card-title>
                        <v-row>
                          <v-col md="2"><v-img alt="LP" class="mr-2" contain min-width="32" src="../assets/imgs/oxxo.png" width="32"/></v-col>
                          <v-col style="text-align: left"><b>OXXO</b></v-col>
                          <v-col style="text-align: right"><b>Total </b><b class="devoluciones-header">{{ infoPeriodo.devolucionesResumen.oxxo }}</b></v-col>
                        </v-row>
                      </v-card-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <div v-if="type == 0">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px; text-align: left;">
                      <div class="card-header">
                        <p class="header-text">Devoluciones por motivo</p>
                      </div>
                    </v-card-title>
                    <v-container>
                      <pie
                        :chart-data="graphmotivo"
                        class="chart"
                        :options="graphmotivo.options"
                      />
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
            <div v-else-if="type == 1">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px">
                      <div class="card-header">
                        <p class="header-text">Devoluciones por estudio</p>
                      </div>
                    </v-card-title>
                    <v-container>
                      <pie
                        :chart-data="graphestudio"
                        class="chart"
                        :options="graphestudio.options"
                      />
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
            <div v-else-if="type == 2">
              <v-card hover class="myCardClass" style="padding: 0px">
                <v-list-item style="padding: 0px">
                  <v-list-item-content style="padding: 0px">
                    <v-card-title style="padding: 0px">
                      <div class="card-header">
                        <p class="header-text">Devoluciones por clínica</p>
                      </div>
                    </v-card-title>
                    <v-container>
                      <pie
                        :chart-data="graphclinica"
                        class="chart"
                        :options="graphclinica.options"
                      />
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </v-container>
          <v-container style="padding-top: 25px">
            <div class="d-flex justify-end">
              <v-btn
                dark
                color="#FFAB0B"
                @click="descargarexcel"
              >
                <v-img
                    alt="arriba"
                    contain
                    src="../assets/imgs/iconos/download.png"
                    style="padding-right: 15px"
                />
                Descargar hoja de cálculo
              </v-btn>
            </div>
            <v-card hover>
              <v-tabs left>
                <v-tab @click="type = 0">Devoluciones por motivos</v-tab>
                <v-tab-item>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="data in infoPeriodo.devolucionesMotivos"
                      :key="data.nombre"
                    >
                      <v-expansion-panel-header>
                        <div style="display: flex">
                          <b>{{ data.nombre }}</b>
                          <b class="header-text" style="margin-left: auto; color: #6c90c0">{{ data.cantidad }}</b>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="data.cantidad == 0">No tiene folios</div>
                        <div v-else>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th class="text-left">Folio Cita</th>
                                <th class="text-left">Plataforma</th>
                                <th class="text-left">Orden Pago</th>
                                <th class="text-left">Mismo Día</th>
                                <th class="text-left">Folio Devolución</th>
                                <th class="text-left">ID Devolución</th>
                                <th class="text-left">Estudio</th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="cita in data.citas" :key="cita.cita">
                                <td>{{ cita.cita }}</td>
                                <td>
                                  <div v-if="cita.plataforma == 1">
                                    <v-img alt="LP" class="mr-2" contain min-width="9" src="../assets/imgs/web32.png" width="32"/>
                                  </div>
                                  <div v-else>
                                    <div v-if="cita.plataforma == 2">
                                      <v-img alt="Asesor" class="mr-2" contain min-width="9" src="../assets/imgs/asesor32.png" width="32"/>
                                    </div>
                                    <div v-else>
                                      <v-img alt="WhatsApp" class="mr-2" contain min-width="9" src="../assets/imgs/wts32.png" width="32"/>
                                    </div>
                                  </div>
                                </td>
                                <td>{{ cita.orden }}</td>
                                <td>
                                  <div v-if="cita.dia == 1">Si</div>
                                  <div v-else>No</div>
                                </td>
                                <td>{{ cita.folio }}</td>
                                <td>{{ cita.iddevolucion }}</td>
                                <td>{{ cita.estudio }}</td>
                                <td>
                                  <v-btn
                                    @click="verMas(cita.folio)"
                                    class="my-button"
                                    style="background-color: #45d8ba !important"
                                  >
                                    Ver más
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
                <v-tab @click="type = 1">Devoluciones por estudio</v-tab>
                <v-tab-item>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="data in infoPeriodo.devolucionesServicios"
                      :key="data.nombre"
                    >
                      <v-expansion-panel-header>
                        <div style="display: flex">
                          <b>{{ data.nombre }}</b>
                          <b class="header-text" style="margin-left: auto; color: #6c90c0">{{ data.cantidad }}</b>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="data.cantidad == 0">No tiene folios</div>
                        <div v-else>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th class="text-left">Folio Cita</th>
                                <th class="text-left">Plataforma</th>
                                <th class="text-left">Orden Pago</th>
                                <th class="text-left">Mismo Día</th>
                                <th class="text-left">Folio Devolución</th>
                                <th class="text-left">ID Devolución</th>
                                <th class="text-left">Estudio</th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="cita in data.citas" :key="cita.cita">
                                <td>{{ cita.cita }}</td>
                                <td>
                                  <div v-if="cita.plataforma == 1">
                                    <v-img alt="LP" class="mr-2" contain min-width="9" src="../assets/imgs/web32.png" width="32"/>
                                  </div>
                                  <div v-else>
                                    <div v-if="cita.plataforma == 2">
                                      <v-img alt="Asesor" class="mr-2" contain min-width="9" src="../assets/imgs/asesor32.png" width="32"/>
                                    </div>
                                    <div v-else>
                                      <v-img alt="WhatsApp" class="mr-2" contain min-width="9" src="../assets/imgs/wts32.png" width="32"/>
                                    </div>
                                  </div>
                                </td>
                                <td>{{ cita.orden }}</td>
                                <td>
                                  <div v-if="cita.dia == 1">Si</div>
                                  <div v-else>No</div>
                                </td>
                                <td>{{ cita.folio }}</td>
                                <td>{{ cita.iddevolucion }}</td>
                                <td>{{ cita.estudio }}</td>
                                <td>
                                  <v-btn
                                    @click="verMas(cita.folio)"
                                    class="my-button"
                                    style="background-color: #45d8ba !important"
                                  >
                                    Ver más
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
                <v-tab @click="type = 2">Devoluciones por clínica</v-tab>
                <v-tab-item>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="data in infoPeriodo.devolucionesCiudades"
                      :key="data.nombre"
                    >
                      <v-expansion-panel-header>
                        <div style="display: flex">
                          <b>{{ data.nombre }}</b>
                          <b class="header-text" style="margin-left: auto; color: #6c90c0">{{ data.cantidad }}</b>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-if="data.cantidad == 0">No tiene Sucursales</div>
                        <div v-else>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th class="text-left">Folio Cita</th>
                                <th class="text-left">Plataforma</th>
                                <th class="text-left">Orden Pago</th>
                                <th class="text-left">Mismo Día</th>
                                <th class="text-left">Folio Devolución</th>
                                <th class="text-left">ID Devolución</th>
                                <th class="text-left">Estudio</th>
                                <th class="text-left"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="cita in data.citas" :key="cita.cita">
                                <td>{{ cita.cita }}</td>
                                <td>
                                  <div v-if="cita.plataforma == 1">
                                    <v-img alt="LP" class="mr-2" contain min-width="9" src="../assets/imgs/web32.png" width="32"/>
                                  </div>
                                  <div v-else>
                                    <div v-if="cita.plataforma == 2">
                                      <v-img alt="Asesor" class="mr-2" contain min-width="9" src="../assets/imgs/asesor32.png" width="32"/>
                                    </div>
                                    <div v-else>
                                      <v-img alt="WhatsApp" class="mr-2" contain min-width="9" src="../assets/imgs/wts32.png" width="32"/>
                                    </div>
                                  </div>
                                </td>
                                <td>{{ cita.orden }}</td>
                                <td>
                                  <div v-if="cita.dia == 1">Si</div>
                                  <div v-else>No</div>
                                </td>
                                <td>{{ cita.folio }}</td>
                                <td>{{ cita.iddevolucion }}</td>
                                <td>{{ cita.estudio }}</td>
                                <td>
                                  <v-btn
                                    @click="verMas(cita.folio)"
                                    class="my-button"
                                    style="background-color: #45d8ba !important"
                                  >
                                    Ver más
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-container>
          <v-container>
            <v-btn @click="regresar" :loading="status" class="my-button" style="margin-bottom: 40px"
              >Regresar</v-btn
            >
          </v-container>
        </v-row>
      </v-container>
    </div>
    <ScrollTopArrow></ScrollTopArrow>
  </div>
</template>

<style>
.my-input {
  border: 0.5px solid #6c90c0 !important;
  padding: 8px 16px !important;
  width: 100% !important;
}

.my-button {
  background-color: #6c90c0 !important;
  color: white !important;
  text-transform: unset !important;
  padding: 5px 10px !important;
}

.devoluciones-header{
  color: #6c90c0;
}

.fecha-header {
  padding-top: 15px;
  font-size: 15px;
}

.mb-2{
  margin-bottom: 5px;
}
</style>

<script>
import devolucionesService from "../services/devoluciones";
import Pie from "../plugins/Pie";
import ScrollTopArrow from "./ScrollTopArrow"

export default {
  components: {
    Pie,
    ScrollTopArrow
  },
  data: () => ({
    dateIni: new Date().toISOString().substr(0, 10),
    dateFin: new Date().toISOString().substr(0, 10),
    status: false,
    statusfolio: true,
    statusfecha: true,
    errorinfomacion: null,
    errorperiodo: null,
    alert: false,
    folio: null,
    fechaIni: false,
    fechaFin: false,
    level: 0,
    type: 0,
    graphmotivo: null,
    graphestudio: null,
    graphclinica: null,
    colors: [
      "#DF0101",
      "#DF3A01",
      "#DF7401",
      "#DBA901",
      "#D7DF01",
      "#A5DF00",
      "#74DF00",
      "#3ADF00",
      "#01DF01",
      "#01DF74",
      "#01DFD7",
      "#01A9DB",
      "#0174DF",
      "#013ADF",
      "#0101DF",
      "#3A01DF",
      "#3104B4",
      "#7401DF",
      "#A901DB",
      "#DF01D7",
      "#DF01A5",
      "#DF0174",
      "#8A0829",
      "#FF0040",
      "#F5BCA9",
      "#CED8F6",
      "#CED8F6",
    ],
    info: null,
    infoPeriodo: null
  }),
  methods: {
    informacion() {
      this.statusfolio = false;
      devolucionesService.getDevolucion(this.folio).then((res) => {
        if (res.status) {
          this.info = res.data[0];
          this.level = 1;
          this.errorinfomacion = null;
          this.statusfolio = true;
        } else {
          this.statusfolio = true;
          this.errorinfomacion = res.message;
        }
      });
    },
    periodo() {
      this.statusfecha = false;
      devolucionesService
        .getDevolucionPeriodo(this.dateIni, this.dateFin)
        .then((res) => {
          if (res.status) {
            this.infoPeriodo = res;
            this.level = 2;
            this.fillGraphMotivos();
            this.fillGraphEstudios();
            this.fillGraphClinica();
            this.errorperiodo = null;
            this.statusfecha = true;
          } else {
            this.errorperiodo = res.message;
            this.statusfecha = true;
          }
        });
    },
    regresar() {
      this.level = 0;
    },
    regresarPeriodo() {
      this.level = 2
    },
    fillGraphMotivos() {
      this.graphmotivo = {
        labels: this.infoPeriodo.graficas.motivos.name,
        datasets: [
          {
            label: "Grafica devoluciones por motivo",
            data: this.infoPeriodo.graficas.motivos.values,
            backgroundColor: this.colors,
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "left",
          },
        },
      };
    },
    fillGraphEstudios() {
      this.graphestudio = {
        labels: this.infoPeriodo.graficas.estudios.name,
        datasets: [
          {
            label: "Grafica devoluciones por estudios",
            data: this.infoPeriodo.graficas.estudios.values,
            backgroundColor: this.colors,
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "left",
          },
        },
      };
    },
    fillGraphClinica() {
      this.graphclinica = {
        labels: this.infoPeriodo.graficas.clinicas.name,
        datasets: [
          {
            label: "Grafica devoluciones por clinicas",
            data: this.infoPeriodo.graficas.clinicas.values,
            backgroundColor: this.colors,
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "left",
          },
        },
      };
    },
    verMas(folio) {
      devolucionesService.getDevolucion(folio).then((res) => {
        this.info = res.data[0];
        this.level = 1;
      });
    },
    oxxo() {
      devolucionesService.setOxxoDevolucion(this.info.idCitaSisPrev).then(() => {
          this.informacion();
      });
    },
    descargarexcel() {
      devolucionesService
        .getDevolucionPeriodoExcel(this.dateIni, this.dateFin)
        .then((res) => {

          if (res.status) {
            devolucionesService.getFile(res.url, res.name);
          }
        });
    },
  },
};
</script>
