<template>
  <v-row style="padding: 30px 50px 100px 50px">
    <v-container>
      <v-card hover class="myCardClass" style="padding: 0px">
        <v-list-item style="padding: 0px">
          <v-list-item-content style="padding: 0px">
            <v-card-title style="padding: 0px">
              <div class="card-header">
                <p class="header-text">Devoluciones</p>
              </div>
              </v-card-title>
              <v-container>
                <v-container>
                  <div>
                    <br>
                    <v-row>
                      <v-col cols="6" class="text-container">
                        <p>{{returnDate(this.monthDev)}}</p>
                      </v-col>
                      <v-col></v-col>
                      <v-col cols="6" sm="6" md="4" class="text-container">
                        <v-menu
                          v-model="fechaDev"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="monthDev"
                              label="Periodo"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="monthDev"
                            type="month"
                            show-current="date"
                            @input="fechaDev = false"
                            @change="changeDevMonth"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </div>
                <v-container>
                  <bar
                    :chart-data="grapdevoluciones"
                    class="chart"
                    :options="grapdevoluciones.options"
                    style="margin-left: -5px"
                  />
                </v-container>
              </v-container>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-container>
    <v-spacer></v-spacer>
    <v-container>
      <v-card hover class="myCardClass" style="padding: 0px">
        <v-list-item style="padding: 0px">
          <v-list-item-content style="padding: 0px">
            <v-card-title style="padding: 0px">
              <div class="card-header">
                <p class="header-text">Reagendados</p>
              </div>
            </v-card-title>
            <v-container>
              <v-container>
                <div>
                  <br>
                  <v-row>
                    <v-col cols="6" class="text-container">
                      <p>{{returnDate(this.monthReg)}}</p>
                    </v-col>
                    <v-col></v-col>
                    <v-col cols="6" sm="6" md="4" class="text-container">
                      <v-menu
                        v-model="fechaReg"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="monthReg"
                            label="Periodo"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="monthReg"
                          type="month"
                          show-current="date"
                          @input="fechaReg = false"
                          @change="changeRegMonth"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
              <v-container class="graph-container">
                <bar
                  :chart-data="graphreagendar"
                  class="chart"
                  :options="graphreagendar.options"
                  style="margin-left: -5px"
                />
              </v-container>
              </v-container>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-container>
  </v-row>
</template>

<style>
.chart {
  height: 300px;
  width: 100%;
  margin: 15px;
}

.myCardClass{
  margin-top: 20px;
}

.card-header{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  background-color: #6c90c0;
  color: white;
  padding: 10px;
}

.header-text{
  margin-bottom: 0px !important;
  font-weight: 600;
}

.text-container{
  padding: 25px;
}

.graph-container{
  padding: 0px 15px;
}
</style>

<script>
import devolucionesService from "../services/devoluciones";
import reagendadasService from "../services/reagendar";
import Bar from "../plugins/Bar";

export default {
  components: {
    Bar,
  },
  data() {
    return {
      datacollection: null,
      grapdevoluciones: null,
      graphreagendar: null,
      date: new Date().toISOString().substr(0, 7),
      monthDev: new Date().toISOString().substr(0, 7),
      monthReg: new Date().toISOString().substr(0, 7),
      fechaDev: false,
      fechaReg: false,
      colors: ["#86e3ce", "#d0e6a5"],
    };
  },
  mounted() {
    this.fillGraphDevoluciones();
    this.fillGraphReagendadas();
  },
  methods: {
    fillGraphDevoluciones() {
      devolucionesService.getGraphValues(this.monthDev).then((res) => {
        res.names.splice(0,1)
        res.values.splice(0,1)
        if (res.status) {
          this.grapdevoluciones = {
            labels: res.names,
            datasets: [
              {
                label: "Devoluciones",
                data: res.values,
                backgroundColor: this.getBgColors(res.names),
              },
            ],
            options: {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: false,
            },
          };
        }
      });
    },
    fillGraphReagendadas() {
      reagendadasService.getGraphValues(this.monthReg).then((res) => {
        res.names.splice(0,1)
        res.citas.splice(0,1)
        res.estudios.splice(0,1)
        if (res.status) {
          this.graphreagendar = {
            labels: res.names,
            datasets: [
              {
                label: "Citas reagendadas",
                data: res.citas,
                backgroundColor: "#86e3ce",
              },
              {
                label: "Estudios reagendados",
                data: res.estudios,
                backgroundColor: "#d0e6a5",
              },
            ],
            options: {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
            },
          };
        }
      });
    },
    getBgColors(datesArray) {
      let array = [];
      datesArray.forEach((element, index) => {
        if (index % 2) {
          array.push("#86e3ce");
        } else {
          array.push("#d0e6a5");
        }
      });
      return array
    },
    changeDevMonth(){
      this.fillGraphDevoluciones()
    },
    changeRegMonth(){
      this.fillGraphReagendadas()
    },
    returnDate(monthString){
      let year = monthString.substring(0, 4)
      let month = monthString.substring(5, 7)
      let months = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

      return months[Number(month)] + ' ' + year
    }
  },
};
</script>
