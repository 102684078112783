import api from './api'
let service = {}

service.getOxxo = function(type) {
    return api.get('api/oxxo/by_steps/' + type).then(res => res.data)
}

service.getUsers = function() {
    return api.get('api/oxxo/users').then(res => res.data)
}

service.changeStep = function(folio, type, comments) {
    return api.post('/api/oxxo/step', {
        "folio": folio,
        "type": type,
        "comments": comments
    }).then(res => res.data)
}

service.changeStepEnv = function() {
    return api.post('/api/oxxo/step_env').then(res => res.data)
}

service.asigneUser = function(folio, asignado) {
    return api.post('/api/oxxo/asigned', {
        "folio": folio,
        "asignado": asignado
    }).then(res => res.data)
}

service.updateCitaInfo = function(numero_tarjeta, clabe_interbancaria, banco, titular, folio) {
    return api.post('/api/oxxo/update_data', {
        "numero_tarjeta": numero_tarjeta,
        "clabe_interbancaria": clabe_interbancaria,
        "banco": banco,
        "titular": titular,
        "folio": folio
    }).then(res => res.data)
}

service.getReportDevValid = function() {
    return api.post('/api/oxxo/descargar_validas').then(res => res.data)
}

service.getFile = function (uri, label) {
    return api.get(uri, { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
}

service.sendEmailDataError = function(email, folio) {
    return api.post('/api/oxxo/email/data_error',{
        "receptor": email,
        "folio": folio
    }).then(res => res.data)
}

service.sentDataEndCita = function(file, folio, email) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('folio', folio)
    formData.append('receptor', email)

    return api.post('/api/oxxo/email/devolution_finished', formData)
    .then(res => res.data)
}

service.sendEmailProcessFailed = function(folio, email) {
    return api.post('/api/oxxo/email/devolution_failed',{
        "receptor": email,
        "folio": folio
    }).then(res => res.data)
}

service.reactivateCita = function(folio) {
    return api.post('/api/oxxo/reactivate_cita', {
        "folio": folio
    }).then(res => res.data)
}

export default service
