import api from './api'
let service = {}

service.getDevolucion = function(folio) {
    return api.get('/api/devolucion/' + folio).then(res => res.data)
}

service.getDevolucionPeriodo = function(fechaIni, fechaFin) {
    return api.post('/api/devolucion/reporte', {
        "fechaIni": fechaIni,
        "fechaFin": fechaFin
    }).then(res => res.data)
}

service.getDevolucionPeriodoExcel = function(fechaIni, fechaFin) {
    return api.post('/api/devolucion/reporte/descargar', {
        "fechaIni": fechaIni,
        "fechaFin": fechaFin
    }).then(res => res.data)
}

service.getGraphValues = function(fecha) {
    return api.get('/api/devolucion/reporte/fecha/' + fecha).then(res => res.data)
}

service.setOxxoDevolucion = function(cita) {
    return api.post('/api/devolucion/oxxo', { "cita": cita }
    ).then(res => res.data)
}

service.getFile = function (uri, label) {
    return api.get(uri, { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
}

export default service
